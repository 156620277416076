.Wysiwyg {
  line-height: 2;

  @media (min-width: $mq-m) {
    font-size: rem(18);
  }

  p, .Embed {
    margin-bottom: rem(30);

    @media (min-width: $mq-m) {
      margin-bottom: rem(40);
    }
  }

  .intro {
    color: $gray-dark;
    font-size: rem(16);
    font-family: $playfair;
    line-height: 1.6;
    font-weight: bold;
    padding-left: rem(30);

    @media (min-width: $mq-m) {
      font-size: rem(22);
      margin-bottom: rem(80);
    }
  }

  &-photo {
    margin: rem(40) rem(-20);

    @media (min-width: $mq-m) {
      margin: rem(40) 0;
    }

    @media (min-width: $mq-l) {
      margin: rem(80) 0;
      width: rem(960);
    }
  }

  blockquote {
    position: relative;
    min-height: rem(80);
    margin: rem(30) 0;
    padding-left: rem(30);
    font-size: rem(20);
    font-weight: bold;
    font-family: $playfair;
    font-style: italic;
    line-height: 1.25;
    max-width: rem(720);
    color: $color1;

    &::before {
      content: "";
      display: block;
      width: rem(5);
      height: rem(80);
      position: absolute;
      top: rem(8);
      left: rem(10);
      background-color: #d0d0ce;

      @media (min-width: $mq-m) {
        left: rem(40);
      }
    }

    @media (min-width: $mq-m) {
      margin: rem(70) 0;
      font-size: rem(30);
      padding-left: rem(110);
    }

    p {
      margin: 0;
    }

    footer {
      text-align: right;
      font-size: rem(16);
      margin-top: rem(20);

      @media (min-width: $mq-m) {
        font-size: rem(20);
      }
    }
  }

  @each $num, $color in $catColors {
    .is-cat#{$num} & {
      blockquote {
        color: #{$color};
      }
    }
  }

  &-btn {
    text-align: center;
    margin: rem(40) 0;

    @media (min-width: $mq-m) {
      text-align: left;
      margin: rem(80) 0;
    }
  }
}
