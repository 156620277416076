.Select {
  &Btn {
    select {
      width: 100%;
    }

    &.MobileHead-select {
      .select2 {
        .select2-selection__rendered {
          background-color: #f1f0ee;
        }
      }
    }

    .select2 {
      width: 100% !important;

      .select2-selection__rendered {
        border: 1px solid #d0d0ce;
        height: rem(40);
        border-radius: rem(20);
        color: #7f7f7f;
        font-size: rem(12);
        padding: 0 rem(40) 0 rem(20);
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
        line-height: rem(40);
        position: relative;

        &::after {
          @extend %icon;
          @extend %icon-arr-top;
          position: absolute;
          right: rem(20);
          top: 50%;
          font-size: rem(10);
          color: $color1;
          transform: translateY(-50%) rotate(180deg);
        }
      }

      &.select2-container--open {
        .select2-selection__rendered {
          border-radius: rem(20) rem(20) 0 0;
        }

        &.select2-container--above {
          .select2-selection__rendered {
            border-radius: 0 0 rem(20) rem(20);
          }
        }
      }
    }

    &--mobile {
      @media (min-width: $mq-m) {
        display: none;
      }
    }
  }
}

.select2-dropdown {
  &.is-btn {
    border: 1px solid #d0d0ce;
    border-top: 0;

    .select2-results__option {
      color: #7f7f7f;
      font-family: $slabo;
      text-transform: none;
      font-size: rem(12);
      font-weight: normal;
      padding: rem(10) rem(20);

      &.select2-results__option--highlighted, &[aria-selected=true] {
        color: $gray-dark;
        background-color: #f1f0ee;
      }

      &[role="group"] {
        padding: 0;

        .select2-results__group {
          color: #fff;
          background-color: $color1;
          padding: rem(10) rem(20);
        }
      }
    }
  }
}
