.Pagination {
  margin: rem(40) 0;
  padding-right: rem(10);

  @media print {
    display: none;
  }

  @media (min-width: $mq-m) {
    display: flex;
    padding: 0;
    margin: rem(160) 0;
    justify-content: space-between;
  }

  .Pagination-list a, .Pagination-active, button {
    width: rem(52);
    height: rem(52);
    display: block;
    text-align: center;
    line-height: rem(50);
    border-radius: 50%;
    font-size: rem(15);
    font-family: $open;
    font-weight: bold;
    color: #a8a9a1;
    border: 1px solid #d0d0ce;
    background-color: #fff;
    position: relative;
    transition: $transition-base;

    @media (min-width: $mq-m) {
      font-size: rem(12);
      width: rem(42);
      height: rem(42);
      line-height: rem(40);
    }

    &:hover {
      color: #fff;
      background-color: #319cc4;
    }

    [class*="icon"] {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .Pagination-prev a, .Pagination-next a {
    color: #2f9bc4;

    &:hover {
      color: #fff;
    }
  }

  &-list {
    display: flex;
    @extend %list-reset;
    justify-content: center;
    margin-bottom: rem(30);

    @media (min-width: $mq-m) {
      margin: 0;
    }

    li {
      margin: 0 rem(5);

      &:not(.Pagination-prev):not(.Pagination-next):not(.Pagination-more):nth-child(n+4) {
        display: none;

        @media (min-width: $mq-m) {
          display: block;
        }
      }
    }

    .Pagination-active {
      color: #fff;
      background-color: #319cc4;
    }
  }

  &-top {
    text-align: center;

    @media (min-width: $mq-m) {
      display: flex;
      align-items: center;
    }

    button {
      color: $color1;
      margin: 0 auto rem(10);

      @media (min-width: $mq-m) {
        order: 2;
        margin: 0 0 0 rem(30);
        background-color: #f1f0ee;
        border-color: #f1f0ee;
      }
    }

    &-txt {
      font-size: rem(13);
      color: #a8a9a1;
    }
  }

  .Layout-next {
    justify-content: center;
    margin: rem(40) 0 rem(80);
  }

  &--next {
    @media (min-width: $mq-m) {
      .Pagination-top {
        display: none;
      }

      .Layout-next {
        margin: 0;
      }
    }
  }
}
