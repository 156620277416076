// Colors

$gray:                      #686868;            // Gray
$gray-dark:                 #140e0e;
$gray-light:                #a8a9a1;
$gray-lighter:              #d0d0ce;

$color1:                    #0c84b1;            // Blue
$color1-light:              #70bad7;

$catColors: (
  1: #009bd5,
  2: #d32938,
  3: #9d3eec,
  4: #ffc600,
  5: #95c52e,
  6: #0fc0f3,
  7: #ed9638,
  8: #ee3fcb,
  9: #4468ed,
  10: #2ec5a0,
  11: #ea285b,
);

// Body

$body-bg:                   #fff;
$body-color:                $gray;

// Fonts

$slabo:                     'Slabo 13px', serif;
$slabo27:                   'Slabo 27px', serif;
$playfair:                  'Playfair Display', serif;
$open:                      'Open Sans', sans-serif;

// Base

$font-size-root:            16px;               // html tag
$em-base:                   $font-size-root;    // Bourbon var for em() and rem() calculations
$font-size-base:            rem(14);            // body tag
$font-family-base:          $slabo;
$line-height-base:          1.5;

$spacer:                    rem(20);

$transition-base:           all .3s ease;

// Wrapper

$wrapper-width-m:           950px;
$wrapper-width-l:           1278px;

// Header

$header-height:             rem(60);
$header-height-m:           rem(80);

$nav-height:                rem(46);
$navDirectory-height:       rem(72);

// MQ

$mq-xs:                     em(480);
$mq-s:                      em(768);
$mq-s-up:                   em(767);
$mq-m:                      em(1024);
$mq-l:                      em(1366);
