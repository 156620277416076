.Radio {
  background-color: #fff;
  padding: rem(15);
  display: flex;
  align-items: center;
  position: relative;
  width: rem(280);
  z-index: 1;

  &::after {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    z-index: -1;
    transition: opacity .6s ease;
    box-shadow: 0 46px 46px rgba(#000, 0.15);
  }

  .no-touchevents &:hover {
    z-index: 10;

    &::after {
      opacity: 1;
    }
  }

  @media (min-width: $mq-m) {
    width: auto;
  }

  & + & {
    margin-top: rem(4);

    @media (min-width: $mq-m) {
      margin-top: rem(16);
    }

    @media (min-width: $mq-l) {
      margin-top: rem(10);
    }
  }

  &-logo {
    width: rem(100);
    text-align: right;

    @media (min-width: $mq-l) {
      width: rem(130);
    }
  }

  &-desc {
    flex: 1;
  }

  &-title {
    font-weight: bold;
    font-size: rem(12);
    font-family: $playfair;
    color: $gray-dark;
    margin-bottom: rem(15);
    min-height: rem(45);
    margin-bottom: rem(2);
  }

  &-time {
    color: #a8a9a1;
    font-size: rem(12);
  }

  &-play {
    font-size: rem(16);
    color: $color1;
    position: absolute;
    bottom: rem(15);
    right: rem(80);

    @media (min-width: $mq-l) {
      right: rem(110);
    }
  }
}