.Nav {
  position: fixed;
  z-index: 100;
  width: rem(230);
  background-color: #fff;
  left: 0;
  top: 0;
  background: #fff;
  height: 100vh;
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
  transition: $transition-base;

  @media print {
    display: none;
  }

  @media (min-width: $mq-m) {
    height: $nav-height;
    z-index: 400;
    width: 100%;
    overflow: visible;
    top: $header-height-m;
    box-shadow: 0 2px 5px rgba(#000, .25);
  }

  &-content {
    padding-bottom: rem(80);

    @media (min-width: $mq-m) {
      padding-bottom: 0;
    }
  }

  &-logo {
    height: $header-height;
    display: block;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $color1;
    border-bottom: 1px solid #127ca4;

    .has-theme & {
      border-bottom: 0;
    }

    @media (min-width: $mq-m) {
      display: none;
    }

    img {
      display: block;
      width: rem(67);
      height: auto;
    }
  }

  &-btns {
    display: flex;
    color: #98daf3;
    background-color: #18a6db;
    border-bottom: 1px solid #ebebeb;

    .has-theme & {
      background: #f8f8f8;
    }

    @media (min-width: $mq-m) {
      display: none;
    }

    &-col {
      flex: 1;

      & + & {
        border-left: 1px solid #58c0e7;
      }
    }

    select {
      width: 100%;
    }

    .select2 {
      width: 100% !important;

      .select2-selection__rendered {
        height: rem(44);
        line-height: rem(44);
        text-align: center;
        color: #98daf3;

        .has-theme & {
          color: $body-color;

          &:after {
            color: $body-color;
          }
        }

        &::after {
          @extend %icon;
          @extend %icon-arr;
          color: #fff;
          font-size: rem(10);
          margin-left: rem(10);
          transform: rotate(90deg);
          transition: $transition-base;
        }
      }

      &.select2-container--open {
        .select2-selection__rendered::after {
          transform: rotate(-90deg);
        }
      }
    }
  }

  &-btn {
    display: block;
    width: 100%;
    height: rem(44);
    line-height: rem(44);
    text-align: center;
  }

  &-menu {
    @extend %list-reset;
    font-family: $open;
    color: #7f7f7e;

    @media (min-width: $mq-m) {
      display: flex;
      justify-content: center;
    }

    > li {
      position: relative;
      
      // Universités d'été
      
      &:first-child {
        > a {
          color: $color1;
        }
      }

      &:last-child {
        ul {
          @media (min-width: $mq-m) {
            left: - rem(20);
          }

          @media (min-width: $mq-l) {
            left: rem(10);
          }
        }
      }

      &.elections {
        color: #ea285b;
        font-weight: 700;

        a:hover {
          color: darken(#ea285b, 10%)
        }
      }

      &.is-open, &.is-active {
        > a {
          background-color: #f4f4f4;

          @media (min-width: $mq-m) {
            background-color: transparent;
            position: relative;
            color: #000 !important;

            &::before {
              content: "";
              display: block;
              position: absolute;
              height: rem(5);
              left: rem(15);
              right: rem(15);
              top: 0;
              background-color: #18a6db;
            }
          }
        }
      }

      &.has-children {
        > a {
          &::after {
            @extend %icon;
            @extend %icon-arr;
            font-size: rem(10);
            color: $color1;
            position: absolute;
            top: 50%;
            right: rem(20);
            transition: $transition-base;
            transform: translateY(-50%) rotate(90deg);

            .has-theme & {
              color: $gray;
            }

            @media (min-width: $mq-m) {
              right: rem(10);
              opacity: 0;
              transform: translateY(-50%) rotate(-90deg);
            }
          }
        }

        &.is-open {
          > a::after {
            transform: translateY(-50%) rotate(-90deg);
          }
        }

        @media (min-width: $mq-m) {
          &:hover {
            > a {
              &::after {
                opacity: 1;
              }
            }

            ul {
              opacity: 1;
              visibility: visible;
            }
          }
        }
      }

      > a {
        border: 1px solid #ebebeb;
        border-top: 0;
        height: rem(56);
        display: flex;
        font-weight: 600;
        position: relative;
        text-transform: uppercase;
        padding: 0 rem(20);
        align-items: center;

        @media (min-width: $mq-m) {
          height: $nav-height;
          border: 0;
          font-size: rem(11);
          padding: 0 rem(30);

          &:hover {
            color: #696969;
          }
        }
      }
    }

    ul {
      display: none;
      @extend %list-reset;
      border-bottom: 1px solid #ebebeb;
      background-color: #f8f8f8;

      @media (min-width: $mq-m) {
        position: absolute;
        left: rem(10);
        top: 100%;
        width: rem(206);
        border: 0;
        opacity: 0;
        display: block;
        visibility: hidden;
        background-color: #fff;
        transition: $transition-base;
        box-shadow: 0 46px 46px rgba(#000, 0.15);
      }

      li {
        @media (min-width: $mq-m) {
          border-top: 1px solid #f1f0ee;
        }

        & + li {
          border-top: 1px solid #fff;

          @media (min-width: $mq-m) {
            border-top: 1px solid #f1f0ee;
          }
        }

        &.is-active {
          a {
            background-color: #f8f8f8;
            color: #000;
            border-left: 5px solid #0fc0f3;

            .has-theme & {
              border-left-color: $gray;
            }
          }
        }
      }

      a {
        padding: rem(10) rem(20);
        display: block;

        @media (min-width: $mq-m) {
          font-size: rem(11);
          font-weight: 600;
          padding: rem(15) rem(20) rem(15) rem(15);
          text-transform: uppercase;
          border-left: rem(5) solid #fff;

          &:hover {
            color: #000;
            background-color: #f8f8f8;
            border-color: #f8f8f8;
          }
        }
      }
    }
  }

  &Directory {
    display: none;
    position: fixed;
    z-index: 300;
    width: 100%;
    left: 0;
    top: $nav-height;
    @extend %list-reset;

    @media (min-width: $mq-m) {
      display: flex;
    }

    li {
      flex: 1;

      & + li {
        border-left: 1px solid #fff;
      }

      &.is-active a, a:hover {
        background-color: #fff;
        color: $gray-dark;
      }
    }

    a {
      color: #a8a9a1;
      font-size: rem(11);
      display: flex;
      font-weight: 600;
      text-transform: uppercase;
      align-items: center;
      justify-content: center;
      height: $navDirectory-height;
      background-color: #fafafa;
      font-family: $open;
    }
  }
}
