// Text

.u-txt {
  &Upper    { text-transform: uppercase !important; }
  &Lower    { text-transform: lowercase !important; }
  &Center   { text-align: center !important; }
  &Left     { text-align: left !important; }
  &Right    { text-align: right !important; }
  &Justify  { text-align: justify !important; }
  &NoWrap   { white-space: nowrap !important; }
  &Break    { word-wrap: break-word !important; }
  &Open     { font-family: $open; }
}

.u-listReset {
  padding-left: 0;
  list-style: none;
}

.u-mobileOnly {
  @media (min-width: $mq-s) {
    display: none;
  }
}

.u-hideDesktop {
  @media (min-width: $mq-m) {
    display: none;
  }
}

.u-showTablet {
  display: none;
  
  @media (min-width: $mq-s) {
    display: block;
  }
}

@each $num, $color in $catColors {
  .u-color#{$num} {
    color: #{$color};
  }
}

.blue-h2 {
  h2 {
    color: map_get($catColors, 1);
  }
}
