.Overlay {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 200;
  opacity: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  visibility: hidden;
  background: rgba(#202020, 0.9);
  transition: all .5s ease;

  @media print {
    background: transparent;
    display: block;
    position: static;
    width: rem(1024);
  }

  .has-footerForm & {
    z-index: 400;
  }

  @each $num, $color in $catColors {
    &[data-cat="#{$num}"] {
      background: rgba($color, 0.9);
    }
  }

  &-close {
    position: absolute;
    top: - rem(21);
    right: - rem(21);
    z-index: 100;

    @media print {
      display: none;
    }
  }

  &.is-active {
    opacity: 1;
    visibility: visible;
  }

  &-content {
    max-height: 100%;
    overflow: auto;
    width: 100%;
    position: relative;
    padding-top: rem(132);
    -webkit-overflow-scrolling: touch;
    overflow-x: hidden;

    @media (min-width: $mq-l) {
      padding-top: rem(152);
    }

    @media print {
      max-height: none;
      overflow: visible;
    }
  }

  &-popin {
    padding-bottom: rem(40);
  }
}
