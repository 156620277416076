.Directory {
  margin-top: rem(40);
}

.Dir {
  &Intro {
    &-title {
      font-size: rem(30);
      color: $color1;
      font-family: $playfair;
      font-weight: normal;
      line-height: 1;
      margin-bottom: rem(30);

      @media (min-width: $mq-m) {
        display: none;
      }
    }

    &-logo {
      margin-bottom: rem(30);
    }

    &-meta {
      color: $color1;
      margin-bottom: rem(40);
    }

    p {
      line-height: 2;
    }
  }

  &Toggle {
    margin-top: rem(30);

    &-head {
      color: $gray-dark;
      font-size: rem(18);
      font-weight: 600;
      font-family: $open;
      position: relative;
      padding: rem(10) rem(80) rem(10) 0;
      text-transform: uppercase;
      margin: rem(40) 0 rem(20);
      transition: $transition-base;

      .is-open & {
        color: $color1;
      }

      @media (min-width: $mq-m) {
        display: flex;
        align-items: center;
      }

      .BtnCircle {
        position: absolute;
        top: 50%;
        right: rem(20);
        transform: translateY(-50%);

        @media (min-width: $mq-m) {
          margin-left: rem(15);
          transform: none;
          position: relative;
          right: auto;
          top: auto;
        }

        [class*="icon"] {
          transform: translate(-50%, -50%) rotate(180deg)
        }
      }

      .is-open & {
        .BtnCircle {
          background-color: $color1;
          border-color: $color1;

          [class*="icon"] {
            color: #fff;
            transform: translate(-50%, -50%) rotate(360deg);
          }
        }
      }
    }

    &-title {
      color: $color1;
      font-size: rem(16);
      font-weight: 600;
      font-family: $open;
      margin: rem(25) 0 rem(15);

      &:first-child {
        margin-top: 0;
      }

      &::before {
        content: "• ";
      }
    }

    &-content {
      display: none;
    }

    .Grid--contact {
      margin: 0;

      .Grid-inner {
        padding: 0;
      }

      .Grid-item {
        @media (min-width: $mq-m) {
          width: 33.3333%;
        }

        &:first-child {
          margin-left: 0;
        }
      }
    }
  }

  &Members {
    font-family: $open;
    font-weight: 600;

    & + & {
      margin-top: rem(5);

      @media (min-width: $mq-m) {
        margin-top: rem(15);
      }
    }

    &-title {
      margin-bottom: rem(5);
      color: $gray-dark;
      font-size: rem(16);
    }

    &-list {
      @extend %list-reset;

      li + li {
        margin-top: rem(5);
      }

      em {
        display: block;
        font-weight: normal;

        @media (min-width: $mq-m) {
          display: inline;

          &::before {
            content: "/ ";
          }
        }
      }
    }
  }

  &Aside {
    &-cat {
      &-title {
        font-family: $open;
        font-weight: 600;
        font-size: rem(12);
        color: #fff;
        height: rem(55);
        line-height: 1.2;
        position: relative;
        display: flex;
        cursor: pointer;
        margin: 0;
        align-items: center;
        padding: 0 rem(50) 0 rem(20);
        text-transform: uppercase;
        background-color: $color1;
        transition: $transition-base;

        .is-open &, &:hover {
          background-color: #18a6db;
        }

        &::before {
          content: "";
          display: block;
          width: rem(30);
          height: rem(30);
          background-color: #fff;
          border-radius: 50%;
          position: absolute;
          right: rem(10);
          top: 50%;
          transform: translateY(-50%);
          transition: $transition-base;

          .is-open & {
            background-color: $color1;
          }
        }

        &::after {
          @extend %icon;
          @extend %icon-arr-top;
          position: absolute;
          right: rem(20);
          color: $color1;
          font-size: rem(10);
          top: 50%;
          transform: translateY(-50%) rotate(180deg);
          transition: $transition-base;

          .is-open & {
            color: #fff;
            transform: translateY(-50%);
          }
        }
      }
    }

    &-content {
      display: none;

      &.is-show {
        display: block;
      }
    }

    &-inner {
      display: flex;
      border-left: 1px solid #ebebeb;
      border-right: 1px solid #ebebeb;
      border-bottom: 1px solid #ebebeb;
    }

    &-wrap {
      flex: 1;
      max-height: rem(825);
      overflow: scroll;
      -webkit-overflow-scrolling: touch;
    }

    &-list {
      @extend %list-reset;

      li {
        & + li {
          border-top: 1px solid #ebebeb;
        }

        &.is-active a, a:hover {
          color: $color1;
        }
      }

      a {
        display: block;
        line-height: 1.2;
        padding: rem(20);
        font-size: rem(12);
        font-weight: 600;
        font-family: $open;
      }
    }

    &-anchors {
      width: rem(50);
      @extend %list-reset;
      padding: rem(20) 0;
      display: flex;
      flex-direction: column;
      border-left: 1px solid #ebebeb;

      li {
        flex: 1;
      }

      a {
        display: block;
        text-align: center;
        font-family: $open;
        font-size: rem(15);
        color: $gray-dark;
        text-align: center;

        &:hover {
          color: $color1;
        }
      }
    }

    &--full {
      margin: 0 rem(-20);

      .DirAside-inner {
        border-left: 0;
      }

      @media (min-width: $mq-m) {
        .DirAside {
          &-cat {
            &-title {
              height: rem(92);
              padding: 0 rem(100) 0 rem(30);
              font-size: rem(16);
              font-weight: bold;
              font-family: $playfair;

              &::before {
                width: rem(42);
                height: rem(42);
                right: rem(30);
              }

              &::after {
                right: rem(46);
              }
            }
          }

          &-list {
            li + li {
              border-top: 2px solid #fff;
            }

            a {
              color: $gray;
              font-size: rem(16);
              font-family: $slabo;
              font-weight: normal;
              background-color: #f1f0ee;
              position: relative;
              z-index: 1;

              &::after {
                content: "";
                display: block;
                width: 100%;
                height: 100%;
                position: absolute;
                left: 0;
                top: 0;
                opacity: 0;
                z-index: -1;
                pointer-events: none;
                transition: opacity .6s ease;
                box-shadow: 0 46px 46px rgba(#000, 0.15);
              }

              &:hover {
                color: $color1;
                z-index: 2;
                background-color: #fff;

                &::after {
                  opacity: 1;
                }
              }
            }
          }
        }
      }
    }
  }
}