.Accordion {
  background-color: #f1f0ee;
  transition: $transition-base;
  margin: rem(20) rem(-20) 0;
  position: relative;
  z-index: 1;
  border-bottom: 1px solid #fff;

  &::after {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    z-index: -1;
    pointer-events: none;
    transition: opacity .6s ease;
    box-shadow: 0 46px 46px rgba(#000, 0.15);
  }

  &::before {
    content: "";
    display: block;
    width: rem(80);
    height: rem(5);
    position: absolute;
    left: 0;
    top: 0;
    transform: scaleX(0);
    transform-origin: 0 50%;
    transition: $transition-base;
    background-color: $color1;
  }

  @media (min-width: $mq-m) {
    margin-left: 0;
    margin-right: 0;
    border: 0;
  }

  &.is-open {
    background-color: #fff;
    border-color: #f1f0ee;
    z-index: 2;

    @media (min-width: $mq-m) {
      border-color: transparent;

      &::after {
        opacity: 1;
      }
    }

    &::before {
      transform: none;
    }
  }

  & + & {
    margin-top: 0;

    @media (min-width: $mq-m) {
      margin-top: rem(2);
    }
  }

  &-head {
    cursor: pointer;
    height: rem(110);
    display: flex;
    align-items: center;
    position: relative;
    padding: 0 rem(80) 0 rem(20);

    @media (min-width: $mq-m) {
      height: rem(90);
      padding-right: rem(120);
    }

    .BtnCircle {
      position: absolute;
      right: rem(20);
      top: 50%;
      transform: translateY(-50%);
      background-color: transparent;

      @media (min-width: $mq-m) {
        right: rem(40);
      }

      [class*="icon"] {
        transform: translate(-50%, -50%) rotate(180deg)
      }
    }

    .is-open &, .no-touchevents &:hover {
      .BtnCircle {
        background-color: $color1;
        border-color: $color1;

        [class*="icon"] {
          color: #fff;
          transform: translate(-50%, -50%) rotate(360deg);
        }
      }
    }
  }

  &-title {
    margin: 0;
    font-size: rem(14);
    font-weight: normal;
    color: $gray-dark;
    font-family: $playfair;

    @media (min-width: $mq-m) {
      font-weight: bold;
      font-size: rem(16);
    }
  }

  &-content {
    padding: rem(20);
    display: none;

    p {
      line-height: 2;
      margin-bottom: rem(30);

      @media (min-width: $mq-m) {
        line-height: 3;
        margin-bottom: rem(50);
      }
    }

    .DL {
      margin-top: rem(30);

      @media (min-width: $mq-m) {
        margin-top: rem(50);
      }
    }
  }

  &-info {
    @extend %list-reset;

    li + li {
      margin-top: rem(10);

      @media (min-width: $mq-m) {
        margin-top: rem(20);
      }
    }

    span {
      color: $color1;
    }
  }
}