// Reset

html {
  font-size: $font-size-root;
  box-sizing: border-box;
  height: 100%;

  @media print {
    width: rem(1024);
  }

  &.has-nav {
    overflow: hidden;
  }
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  height: 100%;
  min-width: rem(320);
  color: $body-color;
  font-size: $font-size-base;
  line-height: $line-height-base;
  font-family: $font-family-base;
  background: $body-bg;
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;

  .has-nav &, &.is-blocked {
    overflow: hidden;
  }

  &.has-map {
    @media (min-width: $mq-m) {
      overflow: hidden;
    }
  }
}

// Typography

a {
  color: inherit;
  text-decoration: none;
  transition: $transition-base;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0 0 $spacer;
}

p {
  margin: 0 0 $spacer;
}

ol,
ul,
dl {
  margin: 0 0 $spacer;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

blockquote {
  margin: 0 0 $spacer;
}

// Images

img {
  vertical-align: middle;
  max-width: 100%;
}

// Main

main {
  z-index: 200;
  min-height: 100%;
  position: relative;
  padding-top: $header-height;
  background-color: #fff;
  transition: all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);

  @media (min-width: $mq-m) {
    position: static;
    padding-top: $header-height-m + $nav-height;
    transition: $transition-base;
  }

  .has-nav & {
    transform: translateX(rem(230));
  }

  .is-form & {
    background-color: #f1f0ee;
  }

  .is-blocked & {
    @media print {
      display: none;
    }
  }

  .is-blocked:not(.no-pad) & {
    padding-top: $nav-height*2;
  }

  .has-filters & {
    @media (min-width: $mq-m) {
      padding-top: $header-height-m + $nav-height*2;
    }
  }

  .has-map & {
    @media (min-width: $mq-m) {
      padding-top: $nav-height*3;
    }
  }
}
