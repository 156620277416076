.TV {
  background-color: $color1;
  padding: rem(60) 0;
  position: relative;
  margin: rem(40) 0 rem(80);

  @media (min-width: $mq-m) {
    margin-top: rem(150);
  }

  &-slider {
    @media (min-width: $mq-m) {
      .js & {
        opacity: 0;
      }
    }
  }

  &-slides {
    min-height: rem(340);

    @media (min-width: $mq-m) {
      min-height: rem(425);
    }

    @media (min-width: $mq-l) {
      min-height: rem(544);
    }

    &.slick-initialized {
      .Box {
        display: block;
      }
    }
  }

  &-list {
    .Box {
      width: rem(278);
      display: none;

      @media (min-width: $mq-m) {
        width: rem(750);
      }

      @media (min-width: $mq-l) {
        width: rem(960);
      }

      [class*="icon"] {
        z-index: 60;
      }

      &.slick-center {
        .Box-thumb {
          &-overlay, 
          &::after {
            opacity: 0;
            transition: all .3s .5s ease;
          }
        }

        .Box-info {
          opacity: 1;
          transition: all .3s .5s ease;
        }
      }

      &:not(.slick-center) {
        &:hover {
          .Box-thumb::after {
            opacity: 0.5;
          }
        }
      }
    }

    .Box-info {
      min-height: rem(180);
      opacity: 0;
      transition: $transition-base;

      @media (min-width: $mq-m) {
        min-height: 0;
      }
    }
  }

  .BtnCircle {
    position: absolute;
    right: rem(20);
    bottom: - rem(21);

    @media (min-width: $mq-m) {
      right: auto;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  &--lite {
    background: transparent;
    padding: 0;

    @media (min-width: $mq-m) {
      margin-bottom: rem(40);
      margin-top: rem(120);
    }

    @media (max-width: $mq-s-up) {
      .Title {
        margin-bottom: rem(80);
      }
    }

    .Title-txt {
      color: $color1;
    }

    .Box-thumb {
      &::after {
        background-color: rgba(#fff, 0.7);
      }
    }

    .slick-arrow {
      color: #fff;
      background-color: $color1;
      border: 0;

      .no-touchevents &:hover {
        color: #2f9bc4;
        background-color: #fff;

        @media (min-width: $mq-m) {
          color: #fff;
          background-color: $color1;
        }
      }

      @media (min-width: $mq-m) {
        color: #2f9bc4;
        background-color: #fff;
      }
    }

    .Box {
      @media (min-width: $mq-m) {
        display: none;
        width: rem(750);
      }

      @media (min-width: $mq-l) {
        width: rem(960);
      }

      .no-touchevents &:hover {
        &::after {
          opacity: 0.8;
        }
      }

      [class*="icon"] {
        @media (min-width: $mq-m) {
          z-index: 60;
        }
      }

      &.slick-center {
        .Box-thumb {
          &::after {
            opacity: 0;
            transition: all .3s .5s ease;
          }
        }

        .Box-info {
          opacity: 1;
          transition: all .3s .5s ease;
        }
      }
    }

    .Box-info {
      @media (min-width: $mq-m) {
        opacity: 0;
        transition: $transition-base;
      }
    }
  }

  &--pub {
    margin-bottom: 0;

    @media (min-width: $mq-m) {
      margin-bottom: rem(40);
    }
  }

  .Box {
    &.is-video {
      .Box-thumb::before {
        @media (min-width: $mq-m) {
          font-size: rem(70);
          transition: $transition-base;
        }

        @media (min-width: $mq-l) {
          font-size: rem(90);
        }
      }

      &:hover {
        .Box-thumb::before {
          opacity: 0.8;
        }
      }
    }

    &.is-pub {
      @media (min-width: $mq-m) {
        height: rem(425);
        display: flex;
        align-items: flex-start;
        padding: rem(52) rem(60);
        background-color: #000 !important;
        margin-bottom: rem(15);
        position: relative;

        &::after {
          content: "";
          display: block;
          width: 100%;
          height: 100%;
          position: absolute;
          left: 0;
          top: 0;
          background-color: rgba(#fff, 0.95);
          transition: $transition-base;
        }

        &::before {
          display: none;
        }

        .no-touchevents &:hover {
          [class*="icon"] {
            color: #fff;
          }
        }

        [class*="icon"] {
          color: #fff;
        }

        .DL-icon {
          position: absolute;
          bottom: rem(-15);
          left: 50%;
          right: auto;
          top: auto;
          z-index: 50;
          transform: translateX(-50%);
        }

        &.slick-center {
          &::after {
            opacity: 0;
            transition: all .3s .5s ease;
          }
        }

        .Box-thumb {
          width: rem(218);
        }

        .Box-info {
          position: relative;
          bottom: auto;
          right: auto;
          flex: 1;
          opacity: 1;
          transition: none;
          padding: 0 0 0 rem(40);
          width: auto;
          background-color: transparent;

          &::before {
            display: none;
          }

          &-title {
            font-size: rem(30);
            color: #fff;
            margin-bottom: rem(20);
          }

          &-time {
            color: #fff;
          }

          &-thumb {
            &::after {
              display: none;
            }
          }

          &-resume {
            color: #fff;
            line-height: 2;
          }
        }
      }

      @media (min-width: $mq-l) {
        height: rem(544);
        padding: rem(84) rem(120);

        .Box-thumb {
          width: rem(257);
        }

        .Box-info {
          padding-left: rem(120);
        }
      }
    }
  }
}
