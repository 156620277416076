.Mobile {
  &Head {
    padding: rem(20);

    @media (min-width: $mq-m) {
      display: none;
    }

    &-select {
      margin-bottom: rem(20);

      &:last-child {
        margin-bottom: 0;
      }
    }

    .Title {
      margin: 0 0 0 rem(-10);
    }

    & + .Layout {
      margin-top: 0;

      @media (min-width: $mq-m) {
        margin-top: rem(50);
      }
    }
  }
}