// Embed

img,
video {
  max-width: 100%;
  height: auto;
}

iframe {
  border: 0;
}

// Responsive video
.Embed {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  height: 0;
  margin-bottom: $spacer;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
  }
}