.popin {
	display: none;
	position:absolute;
	left:50%;
	transform:translateX(-50%);
	top:90px;
	z-index:900;
	width: 275px;
	background:#f1f0ee;
	padding: 20px;

	.Title--raw span {
		padding:0 0 5px 0;
	}

	.Title-txt {
		font-size: rem(25);
	}
	
	p {
		color: $gray-light;
		font-weight: 600;
	}

	.popin-close {
		width:42px;
		height:42px;
		border-radius:50%;
		display: block;
		text-decoration: none;
		text-align: center;
		line-height: 42px;
		background:#fff;
		border:1px solid #d2d2d0;
		position:absolute;
		right:-21px;
		top:-21px;
		font-size: rem(8);
		color: $color1;
	}

}

.popin-overlay {
	display: none;
	width:100%;
	position:absolute;
	height:100%;
	top:0;
	left:0;
	background:rgba(0, 0, 0, 0.8);
	z-index:800;
}