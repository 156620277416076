.Footer {
  padding: rem(30) rem(20);
  display: flex;
  width: 100%;
  position: relative;
  justify-content: space-between;
  align-items: flex-start;
  background-color: #f1f0ee;

  @media print {
    display: none;
  }

  .has-footerForm & {
    z-index: 500;
  }

  @media (min-width: $mq-m) {
    padding: rem(20) rem(40);
    align-items: center;
  }

  @media (min-width: $mq-l) {
    justify-content: center;
  }

  &-links {
    @extend %list-reset;
    color: $gray;
    font-size: rem(13);
    line-height: 1.2;

    @media (min-width: $mq-m) {
      display: flex;
    }

    li + li {
      margin-top: rem(10);

      @media (min-width: $mq-m) {
        margin: 0 0 0 rem(60);
      }
    }

    li {
      &.is-active {
        a {
          color: $color1;
        }
      }
    }

    a:hover {
      color: $gray-dark;
    }
  }

  &-social {
    @extend %list-reset;
    display: flex;
    font-size: rem(28);
    color: $gray;

    @media (min-width: $mq-l) {
      position: absolute;
      right: rem(40);
      top: 50%;
      transform: translateY(-50%);
    }

    li + li {
      margin-left: rem(8);

      @media (min-width: $mq-m) {
        margin-left: rem(20);
      }
    }

    a {
      display: block;

      [class*="icon"] {
        display: block;
      }

      &:hover {
        color: $gray-dark;
      }
    }
  }

  &-form {
    position: absolute;
    bottom: 100%;
    padding: rem(15) rem(20) rem(20);
    background-color: #f1f0ee;
    opacity: 0;
    display: none;
    left: 0;
    visibility: hidden;
    transition: opacity .3s ease;

    @media (min-width: $mq-m) {
      display: block;
    }

    .BtnCircle {
      position: absolute;
      right: rem(-21);
      top: rem(-21);
    }

    &.is-active {
      opacity: 1;
      left: auto;
      transform: none;
      visibility: visible;
    }
  }
}
