.Filters {
  position: fixed;
  z-index: 200;
  left: 0;
  width: 100%;
  top: $header-height;
  background-color: rgba(#000, 0.9);
  height: calc(100vh - #{$header-height});
  transition: $transition-base;
  visibility: hidden;
  opacity: 0;

  .is-directory & {
    top: $nav-height + $navDirectory-height;
  }

  &.is-visible {
    opacity: 1;
    visibility: visible;
  }

  @media (min-width: $mq-m) {
    background-color: #f1f0ee;
    opacity: 1;
    height: auto;
    visibility: visible;
    padding: 0 0 0 rem(40);
    top: $header-height-m + $nav-height;
  }

  &-breadcrumbs {
    display: none;
    color: #a8a9a1;
    font-size: rem(12);
    font-style: italic;

    @media (min-width: $mq-m) {
      display: block;
    }

    a {
      &::after {
        content: " - ";
        margin: 0 rem(5);
      }

      &:hover {
        color: $color1;
      }
    }

    span {
      text-decoration: underline;
    }
  }

  &-right {
    @media (min-width: $mq-m) {
      display: flex;
    }
  }

  &-display {
    display: none;
    @extend %list-reset;

    @media (min-width: $mq-m) {
      display: flex;
    }

    .is-active a {
      color: #4b4949;
      background-color: #fff;
    }

    a {
      display: block;
      height: $nav-height;
      width: $nav-height;
      position: relative;
      color: #adabaa;

      &:hover {
        color: #4b4949;
      }

      [class*="icon"] {
        position: absolute;
        top: 50%;
        left: 50%;
        font-size: rem(14);
        transform: translate(-50%, -50%);
      }
    }
  }

  &-content {
    height: calc(100vh - #{$header-height});
    padding-bottom: rem(80);
    overflow: scroll;
    -webkit-overflow-scrolling: touch;

    @media (min-width: $mq-m) {
      height: $nav-height;
      padding: 0;
      overflow: visible;
      display: flex;
      align-items: center;
      justify-content: space-between;
      -webkit-overflow-scrolling: auto;
    }
  }

  &-themes {
    background-color: #fff;
    position: relative;

    @media (min-width: $mq-m) {
      background-color: transparent;
      display: flex;
      margin-right: rem(20);
    }
  }

  .BtnCircle {
    position: absolute;
    top: 100%;
    right: rem(20);
    margin-top: rem(20);

    @media (min-width: $mq-m) {
      display: none;
    }
  }

  &-type {
    position: relative;
    border-bottom: 1px solid #e6e6e6;

    @media (min-width: $mq-m) {
      border: 0;
      width: rem(210);
      background-color: #e6e6e6;

      & + & {
        margin-left: 1px;
      }

      &:hover {
        .Filters-type-label {
          background-color: #fff;

          &::after {
            transform: rotate(-90deg);
            color: $color1;
          }
        }

        .Filters-type-dropdown {
          opacity: 1;
          visibility: visible;
        }
      }
    }

    &-label {
      color: #888888;
      font-size: rem(20);
      position: relative;
      cursor: pointer;
      font-family: $slabo27;
      padding: rem(10) rem(40) rem(10) rem(20);

      @media (min-width: $mq-m) {
        height: $nav-height;
        line-height: $nav-height;
        padding-top: 0;
        padding-bottom: 0;
        font-size: rem(12);
        font-family: $slabo;
        transition: $transition-base;
      }

      &::after {
        @extend %icon;
        @extend %icon-arr;
        font-size: rem(10);
        color: $color1;
        position: absolute;
        right: rem(20);
        top: 50%;
        transition: $transition-base;
        margin-top: - rem(5);
        transform: rotate(90deg);

        @media (min-width: $mq-m) {
          color: #adabaa;
        }
      }

      span {
        color: #0c84b1;
      }
    }

    &-dropdown {
      @extend %list-reset;
      display: none;

      @media (min-width: $mq-m) {
        display: block;
        transition: $transition-base;
        opacity: 0;
        position: absolute;
        visibility: hidden;
        background-color: #fff;
        width: 100%;
        box-shadow: 0 46px 46px rgba(#000, 0.15);
      }

      li {
        border-top: 1px solid #e6e6e6;

        &.is-active a, a:hover {
          color: #000;
          background-color: #f8f8f8;

          &::before {
            transform: none;
          }
        }
      }

      a {
        display: block;
        color: #7f7f7f;
        font-size: rem(13);
        font-family: $open;
        font-weight: 600;
        padding: rem(15) rem(20);
        text-transform: uppercase;
        position: relative;
        overflow: hidden;

        @media (min-width: $mq-m) {
          font-size: rem(11);
        }

        &::before {
          content: "";
          display: block;
          width: rem(5);
          height: 100%;
          position: absolute;
          left: 0;
          top: 0;
          background-color: $color1;
          transition: $transition-base;
          transform: translateX(rem(-5));

          @each $num, $color in $catColors {
            .Filters-type--cat#{$num} & {
              background-color: #{$color};
            }
          }
        }
      }
    }

    &--med {
      @media (min-width: $mq-m) {
        width: rem(170);
      }
    }

    &--small {
      @media (min-width: $mq-m) {
        width: rem(110);
      }
    }
  }

  &-search {
    padding: rem(10);
    background-color: #f1f0ee;
  }

  &-pages {
    @extend %list-reset;
    display: none;

    @media (min-width: $mq-m) {
      display: flex;
    }

    > li {
      position: relative;
      border-left: 1px solid #fff;

      a:hover, &.is-active a {
        color: #4b4949;
        background-color: #e4e1db;
      }

      > a {
        display: block;
        height: $nav-height;
        line-height: $nav-height;
        white-space: nowrap;
        min-width: rem(176);
        text-align: center;
        color: #a8a9a1;
        font-size: rem(12);
      }

      &.has-children {
        &:hover {
          > a {
            color: #4b4949;
            background-color: #e4e1db;

            &::after {
              opacity: 1;
            }
          }

          ul {
            opacity: 1;
            visibility: visible;
          }
        }

        a {
          &::after {
            @extend %icon;
            @extend %icon-arr-top;
            font-size: rem(10);
            color: $color1;
            margin-left: rem(10);
            opacity: 0;
            transition: $transition-base;
          }
        }
      }
    }

    ul {
      position: absolute;
      left: 0;
      top: 100%;
      width: 100%;
      @extend %list-reset;
      background-color: #fff;
      opacity: 0;
      visibility: hidden;
      transition: $transition-base;
      box-shadow: 0 46px 46px rgba(#000, 0.15);

      li + li {
        border-top: 1px solid #f1f0ee;
      }

      li.is-active a, a:hover {
        background-color: #f8f8f8;
        color: $gray-dark;
      }

      a {
        display: block;
        color: #696969;
        font-size: rem(12);
        font-family: $open;
        text-align: center;
        line-height: 1.2;
        padding: rem(15) rem(20);
      }
    }
  }

  &-print {
    padding-right: rem(20);

    &-btn {
      border: 0;
      background-color: transparent;
      color: #6b6a6a;

      &:hover {
        color: $color1;
      }
    }
  }

  &--full {
    @media (min-width: $mq-m) {
      padding-left: 0;

      .Filters-pages {
        width: 100%;

        > li {
          flex: 1;

          > a {
            min-width: 0;
            padding: 0 rem(20);
          }
        }
      }
    }
  }
}
