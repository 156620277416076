.DL {
  position: relative;
  display: block;
  z-index: 1;
  padding: rem(15) rem(80) rem(15) rem(20);
  background-color: #f1f0ee;

  @media (min-width: $mq-m) {
    padding-top: rem(20);
    padding-bottom: rem(20);
  }

  &--alone {
    margin-left: rem(-20);
    margin-right: rem(-20);

    @media (min-width: $mq-m) {
      margin-left: 0;
      margin-right: 0;
    }
  }

  &::before {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    z-index: -1;
    pointer-events: none;
    transition: opacity .6s ease;
    box-shadow: 0 46px 46px rgba(#000, 0.15);
  }

  .no-touchevents &:hover {
    background-color: #fff;
    z-index: 2;

    &::before {
      opacity: 1;
    }

    .DL-icon {
      background-color: $color1;
      border-color: $color1;

      i[class*="icon"] {
        color: #fff !important;
      }
    }
  }

  & + & {
    margin-top: rem(2);
  }

  .Post-content & {
    margin-left: rem(-20);
    margin-right: rem(-20);

    @media (min-width: $mq-m) {
      margin-left: 0;
      margin-right: 0;
    }
  }

  &-size {
    font-family: $open;
    font-weight: bold;
    color: $color1;
    text-transform: uppercase;
    font-size: rem(12);
    margin-bottom: rem(10);

    i {
      color: #bbbcb6;
      font-size: rem(15);
      margin-right: rem(3);
    }
  }

  &-title {
    font-family: $playfair;
    font-size: rem(14);
    color: $gray-dark;
    margin: 0;

    @media (min-width: $mq-m) {
      font-size: rem(16);
    }

    @media (min-width: $mq-l) {
      font-size: rem(20);
    }
  }

  &-icon {
    width: rem(42);
    height: rem(42);
    line-height: 1;
    border: 1px solid $gray-lighter;
    border-radius: 50%;
    position: absolute;
    right: rem(20);
    top: 50%;
    font-size: rem(14);
    transform: translateY(-50%);
    transition: $transition-base;
    background-color: #fff;

    @media (min-width: $mq-m) {
      right: rem(40);
    }

    i[class*="icon"] {
      color: $color1-light !important;
      top: 50%;
      left: 50%;
      position: absolute;
      right: auto;
      transition: $transition-base;
      transform: translate(-50%, -50%);
    }
  }
}
