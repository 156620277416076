.Tabs {
  position: relative;

  .Title, .Grid-head {
    @media (min-width: $mq-m) {
      display: none;
    }
  }

  &-nav {
    position: absolute;
    right: 0;
    top: rem(8);
    z-index: 80;
    display: none;
    padding-left: rem(30);

    @media (min-width: $mq-m) {
      display: block;
    }

    @media (min-width: $mq-l) {
      width: rem(272);
    }

    &::before {
      content: "";
      display: block;
      width: rem(5);
      position: absolute;
      top: 0;
      left: 0;
      bottom: rem(6);
      background-color: #d0d0ce;
    }

    &::after {
      content: "";
      display: block;
      width: rem(5);
      position: absolute;
      top: 0;
      left: 0;
      bottom: rem(90);
      background-color: $color1;
      transition: $transition-base;
    }

    &.is-last {
      &::after {
        bottom: rem(6);
      }
    }

    &.is-first {
      &::after {
        bottom: rem(160);
      }
    }

    &-title {
      display: block;
      margin-bottom: rem(40);
      font-family: $slabo;
      color: #a8a9a1;
      line-height: 1;
      font-size: rem(12);
      text-transform: uppercase;

      .is-active & {
        color: $gray-dark;
      }
    }

    &-btn {
      display: block;
      font-size: rem(30);
      color: #d0d0ce;
      line-height: 1.2;
      font-family: $playfair;

      &.is-active {
        color: $gray-dark !important;
      }

      &:hover {
        color: $color1;
      }

      & + & {
        margin-top: rem(40);
      }
    }

    &-toggle {
      display: block;
      width: rem(23);
      height: rem(23);
      background-color: #f1f1f1;
      border-radius: 50% 50%;
      border: 0;
      position: absolute;
      top: rem(85);
      left: - rem(9);
      z-index: 10;
      transition: $transition-base;
      box-shadow: 0 1px 3px rgba(#000, .25);

      .is-last & {
        top: rem(160);
      }

      .is-first & {
        top: rem(10);
      }
    }
  }

  &-content {
    transition: $transition-base;

    @media (min-width: $mq-m) {
      display: none;

      &.is-active {
        display: block;
      }
    }

    &.is-hidden {
      opacity: 0;
      transform: translateX(rem(20));

      &.anim-in {
        opacity: 1;
        transform: none;
      }
    }

    &.anim-out {
      transform: translateX(rem(-20));
      opacity: 0;
    }
  }
}
