.Team {
  margin: 0 rem(-20);
  position: relative;
  z-index: 1;
  background-color: #f1f0ee;
  transition: $transition-base;

  &::after {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    z-index: -1;
    pointer-events: none;
    transition: opacity .6s ease;
    box-shadow: 0 46px 46px rgba(#000, 0.15);
  }

  @media (min-width: $mq-m) {
    margin-left: 0;
    margin-right: 0;
  }

  &.is-open {
    background-color: #fff;
    z-index: 2;

    @media (min-width: $mq-m) {
      &::after {
        opacity: 1;
      }
    }
  }

  & + & {
    margin-top: rem(20);

    @media (min-width: $mq-m) {
      margin-top: rem(10);
    }
  }

  &-head {
    position: relative;

    @media (min-width: $mq-m) {
      display: flex;
      justify-content: space-between;
    }

    &::before {
      content: "";
      display: block;
      width: rem(5);
      height: rem(80);
      background-color: #d0d0ce;
      position: absolute;
      left: 0;
      top: 0;
      transition: $transition-base;

      .is-open & {
        background-color: $color1;
      }
    }

    .BtnCircle {
      position: absolute;
      right: rem(10);
      top: rem(20);

      @media (min-width: $mq-m) {
        top: rem(120);
        left: rem(90);
        right: auto;
      }

      @media (min-width: $mq-l) {
        left: rem(135);
      }

      [class*="icon"] {
        transform: translate(-50%, -50%) rotate(180deg);
      }

      .no-touchevents &:hover, .is-open & {
        background-color: $color1;
        border-color: $color1;

        [class*="icon"] {
          color: #fff;
          transform: translate(-50%, -50%) rotate(360deg);
        }
      }
    }
  }

  &-title {
    font-family: $open;
    font-size: rem(12);
    color: $color1;
    text-transform: uppercase;
    margin: 0;
    height: rem(80);
    padding: 0 rem(80) 0 rem(35);
    display: flex;
    align-items: center;

    @media (min-width: $mq-m) {
      flex: 1;
      padding: 0 rem(25);
    }

    @media (min-width: $mq-l) {
      padding-left: rem(35);
    }
  }

  &-list {
    @extend %list-reset;
    display: flex;
    flex-wrap: wrap;

    @media (min-width: $mq-m) {
      width: rem(488);
      justify-content: flex-end;
    }

    @media (min-width: $mq-l) {
      width: rem(648);
    }

    &-item {
      width: 50%;
      height: rem(278);
      text-align: center;
      border-top: 2px solid #fff;
      padding: rem(50) rem(30) rem(20);
      background: linear-gradient(to bottom, rgba(255,255,255,0.001) 0%,rgba(255,255,255,0.001) 32%,#e8e8e5 33%,rgba(255,255,255,0.001) 38%);

      @media (min-width: $mq-m) {
        width: 25%;
        padding: rem(50) rem(20) rem(20);
        border-left: 1px solid #fff;
        border-top: 0;
      }

      &:nth-child(2n) {
        border-left: 2px solid #fff;

        @media (min-width: $mq-m) {
          border-left: 1px solid #fff;
        }
      }

      img {
        display: block;
        border-radius: 50%;
        margin: 0 auto rem(25);
      }
    }

    &-job {
      font-family: $open;
      font-size: rem(12);
      color: $color1;
      font-weight: bold;
      margin-bottom: rem(10);
      text-transform: uppercase;
    }

    &-name {
      font-size: rem(14);
      color: $gray-dark;
      font-family: $playfair;
      line-height: 1.2;
      font-weight: bold;
    }
  }

  &-more {
    display: none;

    &-line {
      padding: rem(20) 0;

      @media (min-width: $mq-m) {
        display: flex;

        .Team-list-item {
          height: rem(230);
          padding: rem(20);
        }
      }
    }

    &-desc {
      padding: 0 rem(10);

      @media (min-width: $mq-m) {
        flex: 1;
        padding: 0 rem(20);
      }

      @media (min-width: $mq-l) {
        padding-right: rem(50);
      }

      p, li {
        color: $gray;
        font-size: rem(14);
        margin-bottom: 0;

        @media (min-width: $mq-m) {
          font-size: rem(12);
        }

        @media (min-width: $mq-l) {
          line-height: 1.8;
        }
      }
    }

    &-title {
      font-family: $open;
      font-size: rem(16);
      color: $gray-dark;
      text-transform: uppercase;
      margin-bottom: rem(20);

      @media (min-width: $mq-m) {
        font-size: rem(12);
      }

      @media (min-width: $mq-l) {
        margin-bottom: rem(40);
      }
    }
  }
}