.Map {
  @media (min-width: $mq-m) {
    display: flex;
    height: calc(100vh - #{$nav-height*3});
  }

  &-items {
    @media (min-width: $mq-m) {
      width: rem(314);
      overflow: scroll;
      -webkit-overflow-scrolling: touch;
    }

    @media (min-width: $mq-l) {
      width: rem(406);
    }

    &-inner {
      @media (min-width: $mq-m) {
        padding: rem(40);
      }
    }
  }

  &-canvas {
    display: none;

    @media (min-width: $mq-m) {
      display: block;
      flex: 1;
    }
  }

  &-info {
    background-color: #fff;
    border-radius: rem(5);
    position: relative;
    box-shadow: 0 46px 46px rgba(#000, 0.15);

    &::after {
      top: 100%;
      left: rem(30);
      border: solid transparent;
      content: " ";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
      border-color: rgba(255, 255, 255, 0);
      border-top-color: #ffffff;
      border-width: 13px;
    }
  }

  &-tip {
    padding: rem(15) rem(20);

    &-title {
      font-family: $playfair;
      color: $color1;
      font-size: rem(16);
      margin-bottom: rem(15);
      max-width: rem(200);
    }

    &-btm {
      display: flex;
      color: $gray-light;
      font-size: rem(12);
    }

    &-tel {
      width: 50%;

      span {
        color: #000;
        font-size: rem(11);
        font-family: $open;
        font-weight: 600;
        text-transform: uppercase;
      }
    }

    &-contact {
      padding-left: rem(25);
      position: relative;

      &::before {
        @extend %icon;
        @extend %icon-mail;
        color: #000;
        transition: $transition-base;
        position: absolute;
        left: 0;
        top: 50%;
        font-size: rem(16);
        transform: translateY(-50%);
      }

      &:hover::before {
        color: $color1;
      }
    }
  }

  .u-wrapper {
    @media (min-width: $mq-m) {
      width: auto;
    }
  }

  .Grid {
    @media (min-width: $mq-m) {
      margin: 0;

      &-head {
        position: static;
      }

      &-item {
        width: 100%;

        &:first-child {
          margin-left: 0;
        }

        & + .Grid-item {
          margin-top: rem(15);
        }
      }
    }
  }
}