.Program {
  margin: 0 rem(-20);

  @media (min-width: $mq-m) {
    margin: 0;
  }

  & + & {
    margin-top: rem(2);
  }

  &-head {
    cursor: pointer;
    padding: rem(15) rem(100) rem(15) rem(20);
    position: relative;
    background-color: $color1;
    transition: $transition-base;

    @media (min-width: $mq-m) {
      padding-top: rem(20);
      padding-bottom: rem(20);
    }

    @media (min-width: $mq-l) {
      padding-bottom: rem(40);
    }

    .BtnCircle [class*="icon"] {
      transform: translate(-50%, -50%) rotate(180deg);
    }

    .is-open &, .no-touchevents &:hover {
      background-color: #18a6db;

      .BtnCircle {
        background-color: $color1;
        border-color: $color1;

        [class*="icon"] {
          color: #fff;
          transform: translate(-50%, -50%) rotate(360deg);
        }
      }
    }

    .BtnCircle {
      right: rem(20);
      top: 50%;
      transform: translateY(-50%);
      position: absolute;
    }
  }

  &-more {
    display: none;
  }

  &-date {
    color: #5dc7ee;
    font-size: rem(11);
    font-weight: bold;
    text-transform: uppercase;
    font-family: $open;
    margin-bottom: rem(10);
  }

  &-title {
    margin: 0;
    font-size: rem(16);
    color: #fff !important;
    margin: 0;
    font-family: $playfair;

    @media (min-width: $mq-l) {
      font-size: rem(20);
    }
  }

  .DL {
    margin-top: rem(2);

    &-icon {
      @media (min-width: $mq-m) {
        right: rem(20);
      }
    }
  }

  &-list {
    @extend %list-reset;

    li + li {
      margin-top: rem(2);
    }

    a {
      display: block;
      padding: rem(20);
      background-color: #f1f0ee;
      position: relative;
      z-index: 1;

      &::before {
        content: "";
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        opacity: 0;
        z-index: -1;
        pointer-events: none;
        transition: opacity .6s ease;
        box-shadow: 0 46px 46px rgba(#000, 0.15);
      }

      .no-touchevents &:hover {
        background-color: #fff;
        z-index: 2;
        
        &::before {
          opacity: 1;
        }
      }
    }
  }

  &.with-padding {
    .Program-more {
      padding: 0 20px;
    }
  }
}