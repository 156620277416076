.Btn {
  background-color: #fff;
  white-space: nowrap;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #2f9bc4;
  font-size: rem(13);
  text-transform: uppercase;
  padding: rem(15) rem(40);
  border: 1px solid #d0d0ce;
  border-radius: rem(30);

  &:hover {
    color: #fff;
    background-color: $color1;
    border-color: $color1;
  }

  &--ok {
    background-color: #03a154;
    border-radius:0;
    font-family: $open;
    color:#fff;
    width:100%;
    text-align: center;

  }

  &--bg {
    color: #fff !important;
    background-color: $color1;
    border-color: $color1;
    text-transform: none;
    text-decoration: none !important;

    &:hover {
      border-color: #d0d0ce;
      background-color: #fff;
      color: #2f9bc4 !important;
    }
  }

  &--lower {
    text-transform: none;
  }

  &--theme {
    color: #000;
    text-transform: none;
  
    &:hover {
      background: #F2F2F2;
      color: #000;
      border-color: transparent;
    }
  }

  &Circle {
    color: $color1;
    font-size: rem(8);
    font-weight: bold;
    display: block;
    width: rem(42);
    height: rem(42);
    border-radius: 50%;
    text-align: center;
    line-height: rem(42);
    border: 1px solid #d1d1cf;
    background-color: #fff;
    position: relative;
    transition: $transition-base;

    [class*="icon"] {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) rotate(0deg);
    }

    .no-touchevents &:hover {
      background-color: #2f9bc4;
      border-color: #2f9bc4;

      i {
        color: #fff;
        transform: translate(-50%, -50%) rotate(360deg);
      }
    }

    i {
      transition: $transition-base;
    }

    &--filters {
      [class*="icon"] {
        font-size: rem(18);
      }

      @media (min-width: $mq-m) {
        display: none;
      }
    }

    &--prev {
      display: none;

      @media (min-width: $mq-m) {
        display: block;
      }
    }
  }

  &Slide {
    display: block;
    width: rem(136);
    height: rem(136);
    color: #fff;
    background-color: #000;
    border-radius: 50%;
    position: fixed;
    z-index: 210;
    top: 50%;
    font-size: rem(20);
    left: - rem(74);
    margin-top: - $nav-height;
    box-shadow: 0 0 3px rgba(#000, 0.3);

    @media print {
      display: none;
    }

    &:hover {
      background-color: #4b4747;
    }

    i {
      position: absolute;
      top: 50%;
      right: rem(34);
      transform: translateY(-50%);
    }

    &--next {
      left: auto;
      right: - rem(74);

      i {
        right: auto;
        left: rem(34);
        transform: translateY(-50%) rotate(180deg);
      }
    }
  }

  &Close {
    display: inline-block;
    width: rem(36);
    height: rem(36);
    position: absolute;
    right: 0;
    top: 0;
    border: 0;
    padding: 0;
    z-index: 100;
    color: $gray-light;
    background-color: transparent;

    &::after {
      @extend %icon;
      @extend %icon-close;
      font-size: rem(7);
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }

    &:hover {
      color: $color1;
    }
  }
}
