.Gallery {
  margin-bottom: rem(25);

  @media (min-width: $mq-l) {
    margin-bottom: rem(60);
  }

  &-fullscreen {
    color: #fff;
    font-size: rem(24);
    line-height: 1;
    position: absolute;
    z-index: 80;
    right: rem(20);
    bottom: rem(20);
    padding: 0;
    border: 0;
    background-color: transparent;
    transition: $transition-base;

    @media (min-width: $mq-m) {
      font-size: rem(32);
    }

    &:hover {
      opacity: 0.8;
    }
  }

  &-full {
    position: relative;
    @media (max-width: $mq-xs) {
      background-color: rgba(#212121, 0.2);
    }

    &-slide {
      outline: 0;
      display: none;
    }
  }

  &-thumbs {
    cursor: grab;
    position: relative;
    margin-top: rem(20);
    padding-right: rem(100);

    @media (min-width: $mq-m) {
      display: block;
      padding-right: rem(120);
    }

    @media (min-width: $mq-l) {
      margin-top: rem(24);
    }

    &-num {
      font-size: rem(50);
      color: #adabaa;
      font-size: rem(50);
      font-family: $playfair;
      position: absolute;
      right: rem(20);
      top: 50%;
      line-height: 1;
      transform: translateY(-50%);

      @media (min-width: $mq-m) {
        right: 0;
      }

      span {
        color: $color1;
      }
    }

    &-slide {
      outline: 0;
      margin-right: rem(20);
      background-color: #000;
      position: relative;
      display: none;

      &::after {
        content: "";
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        border: 2px solid #0c84b1;
        opacity: 0;
        transition: $transition-base;
      }

      &:hover, &.slick-current {
        &::after {
          opacity: 1;
        }
      }

      @media (min-width: $mq-l) {
        margin-right: rem(24);
      }
    }
  }
}