.Pushs {
  padding: rem(50) 0;

  @media (min-width: $mq-m) {
    padding: rem(80) 0;
  }

  &-wrapper {
    overflow: scroll;
    -webkit-overflow-scrolling: touch;

    @media (min-width: $mq-m) {
      overflow: visible;
    }
  }

  &-item {
    display: table-cell;

    @media (min-width: $mq-m) {
      display: block;
      flex: 1;
      padding: 0 rem(5);
    }

    & + & {
      border-left: 2px solid transparent;

      @media (min-width: $mq-m) {
        border: 0;
      }
    }
  }

  &-list {
    display: table;

    @media (min-width: $mq-m) {
      display: flex;
      margin: 0 rem(-5);
    }
  }

  &-link {
    display: block;
    width: rem(236);
    height: rem(416);
    position: relative;
    background-size: cover;

    @media (min-width: $mq-m) {
      width: 100%;
      height: rem(386);
    }

    @media (min-width: $mq-l) {
      height: rem(516);
    }

    &:hover {
      .BtnCircle {
        background-color: $color1;
        border-color: $color1;

        i {
          color: #fff;
          transform: translate(-50%, -50%) rotate(360deg);
        }
      }
    }
  }

  &-title {
    bottom: rem(140);
    position: absolute;
    left: 0;
    right: 0;
    line-height: 1.1;
    width: 100%;
    justify-content: center;
    padding: 0 rem(20);
    height: rem(100);
    display: flex;
    align-items: center;
    color: #fff;
    margin: 0;
    font-size: rem(30);
    font-family: $playfair;
    text-align: center;

    @media (min-width: $mq-m) {
      bottom: rem(100);
    }

    @media (min-width: $mq-l) {
      bottom: rem(130);
    }
  }

  .BtnCircle {
    position: absolute;
    bottom: rem(70);
    left: 50%;
    transform: translateX(-50%);
    background-color: #fff;
    border-color: #fff;

    @media (min-width: $mq-m) {
      bottom: rem(45);
    }

    @media (min-width: $mq-m) {
      bottom: rem(60);
    }

    i {
      color: $color1;
    }

    @media (min-width: $mq-m) {
      bottom: rem(40);
    }

    @media (min-width: $mq-l) {
      bottom: rem(60);
    }

    &:hover {
      background-color: #fff;
      border-color: #fff;
    }
  }
}
