.Title {
  margin-bottom: rem(30);
  line-height: 1;
  position: relative;

  @media (min-width: $mq-m) {
    max-width: rem(236);
  }

  @media (min-width: $mq-l) {
    max-width: rem(318);
  }

  .BtnCircle {
    position: absolute;
    right: rem(20);
    top: - rem(3);
    z-index: 90;

    @media (min-width: $mq-m) {
      left: 0;
      top: rem(120);
    }

    @media (min-width: $mq-l) {
      top: rem(140);
    }
  }

  &-wrap {
    @media (min-width: $mq-m) {
      min-height: rem(68);
    }
  }

  &-txt {
    font-size: rem(30);
    margin: 0;
    font-weight: normal;
    color: $gray-dark;
    font-family: $playfair;
    padding-left: rem(30);
    position: relative;
    z-index: 80;
    line-height:  1.1;
    padding-right: rem(80);

    @media (min-width: $mq-m) {
      font-size: rem(26);
      padding-right: 0;
    }

    @media (min-width: $mq-l) {
      /*font-size: 1.8vw;*/
      font-size: rem(38);
    }

    &::before {
      content: "";
      display: block;
      width: rem(5);
      height: rem(80);
      position: absolute;
      top: rem(8);
      left: rem(10);
      background-color: #d0d0ce;

      @media (min-width: $mq-m) {
        top: 0;
        left: 0;
      }
    }

    em {
      color: #d0d0ce;
      font-style: normal;
    }

    &--small {
      @media (min-width: $mq-m) {
        font-size: rem(30);
      }

      @media (min-width: $mq-l) {
        font-size: rem(40);
      }
    }
  }

  span {
    display: none;
    font-family: $slabo;
    color: #a8a9a1;
    line-height: 1;
    font-size: rem(12);
    text-transform: uppercase;

    @media (min-width: $mq-m) {
      display: block;
    }
  }

  strong {
    font-weight: normal;

    @media (max-width: $mq-s-up) {
      text-transform: lowercase;
    }

    span {
      @media (max-width: $mq-s-up) {
        display: inline;
        color: $gray-dark;
        font-family: $playfair;
        font-size: rem(30);
        font-weight: normal;
        text-transform: none;
      }
    }
  }

  &-sub {
    color: #a8a9a1;
    line-height: 1;
    font-size: rem(12);
    margin: 0 0 rem(6);
    font-weight: normal;
    padding-left: rem(30);
    text-transform: uppercase;
  }

  .Tag {
    margin: rem(14) 0 0 rem(30);

    @media (min-width: $mq-m) {
      margin-top: rem(2);
    }
  }

  &-tabs {
    @extend %list-reset;
    display: flex;
    position: relative;
    flex-direction: column;
    padding-left: rem(10);

    @media (min-width: $mq-m) {
      padding-left: 0;

      &::before {
        content: "";
        display: block;
        width: rem(5);
        height: rem(30);
        position: absolute;
        left: 0;
        z-index: 10;
        top: - rem(18);
        background-color: $color1;
      }

      &::after {
        content: "";
        display: block;
        width: rem(23);
        height: rem(23);
        background-color: #f1f1f1;
        border-radius: 50% 50%;
        border: 0;
        position: absolute;
        top: rem(5);
        left: - rem(9);
        z-index: 20;
        transition: $transition-base;
        box-shadow: 0 1px 3px rgba(#000, .25);

        @media (min-width: $mq-l) {
          top: rem(10);
        }
      }

      &[data-step="2"] {
        &::before {
          height: rem(90);

          @media (min-width: $mq-l) {
            height: rem(100);
          }
        }

        &::after {
          top: rem(65);

          @media (min-width: $mq-l) {
            top: rem(80);
          }
        }
      }

      &[data-step="3"] {
        &::before {
          height: rem(145);

          @media (min-width: $mq-l) {
            height: rem(185);
          }
        }

        &::after {
          top: rem(125);

          @media (min-width: $mq-l) {
            top: rem(150);
          }
        }
      }
    }

    .Tag {
      margin-left: rem(20);

      @media (min-width: $mq-m) {
        margin-left: rem(30);
      }
    }

    li {
      font-family: $playfair;
      font-size: rem(20);
      position: relative;
      line-height: 1;
      margin-top: rem(10);

      @media (min-width: $mq-m) {
        margin-top: 0;
        font-size: rem(30);

        &:first-child {
          min-height: 0;

          > a {
            padding-top: 0 !important;
          }
        }

        &:last-child {
          > a {
            padding-bottom: 0 !important;
          }
        }
      }

      @media (min-width: $mq-l) {
        font-size: rem(40);
      }

      &::before {
        content: "";
        display: block;
        width: rem(5);
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        background-color: #d0d0ce;
      }

      &.is-active {
        font-size: rem(30);
        order: -1;
        margin-top: 0;

        &:not(:first-child) {
          min-height: rem(62);
        }

        @media (min-width: $mq-m) {
          order: 0;
        }

        @media (min-width: $mq-l) {
          font-size: rem(40);
        }

        &::before {
          top: - rem(18);
          background-color: $color1;

          @media (min-width: $mq-m) {
            background-color: #d0d0ce;
          }
        }

        &:last-child {
          @media (min-width: $mq-m) {
            min-height: 0;

            &::before {
              @media (min-width: $mq-m) {
                bottom: rem(10);
              }
            }
          }
        }

        > a {
          color: $color1;
          padding-top: 0;
          padding-bottom: 0;

          @media (min-width: $mq-m) {
            padding-top: rem(15);
            padding-bottom: rem(15);
          }
        }
      }

      > a {
        color: #d0d0ce;
        display: block;
        padding: rem(15) 0 rem(15) rem(20);

        @media (min-width: $mq-m) {
          padding-left: rem(30);
        }

        &:hover {
          color: $color1;
        }
      }
    }

    &--mobile {
      @media (min-width: $mq-m) {
        display: none;
      }
    }
  }

  .TV & {
    padding-left: rem(10);

    @media (min-width: $mq-m) {
      position: absolute;
      left: 0;
      width: 100%;
      top: -  rem(70);
      max-width: none;
    }

    &-txt {
      color: #fff;

      @media (min-width: $mq-m) {
        color: $gray-dark;
      }
    }
  }

  .Cases & {
    padding-left: rem(10);

    @media (min-width: $mq-m) {
      position: absolute;
      left: 0;
      width: 100%;
      top: -  rem(70);
    }
  }

  &--tabs {
    margin-bottom: rem(20);

    .Title-txt {
      &::before {
        top: 0;
      }

      span {
        display: block;
      }
    }
  }

  &--alone {
    margin: rem(20) 0 rem(15);

    @media (min-width: $mq-m) {
      display: none;
    }

    .Title-txt {
      &::before {
        display: none;
      }
    }
  }

  &--showSub {
    .Title-txt {
      span {
        display: block;
      }

      &::before {
        top: 0;
      }
    }
  }

  &--full {
    @media (min-width: $mq-m) {
      display: flex;
      justify-content: center;
      max-width: none;
    }
  }

  &--minH {
    min-height: rem(80);
  }

  &--toggle {
    .Title-txt {
      @media (min-width: $mq-m) {
        display: none;
      }

      & + .Title-txt {
        display: none;

        @media (min-width: $mq-m) {
          display: block;
        }
      }
    }
  }

  &--raw {
    .Title-txt {
      padding-left: 0;

      &::before {
        display: none;
      }
    }

    span {
      display: block;
    }
  }

  &--classic {
    @media (max-width: $mq-m - em(1)) {
      margin-left: 0 !important;

      .Title-txt {
        padding-left: 0;

        &::before {
          display: none;
        }
      }
    }

    @media (min-width: $mq-m) {
      min-height: rem(80);
    }
  }

  &--no-border {
    max-width: none;
  
    .Title-txt {
      padding-left: 0;
      padding-right: 0;

      &::before {
        display: none;
      }
    }    
  }

  &--big {
    .Title-txt {
      @media (min-width: $mq-m) {
        font-size: rem(38);
      }      
    }
  }

  &--inverted {

    span {
      margin-top: 10px;

      @media (min-width: $mq-m) {
        margin-top: 15px;
      }
    }
  }

  #contactPressText {
    line-height: 1.5;
    margin-top: rem(45);
    font-size: 1rem;
    margin-left: 32px;
  }
}
