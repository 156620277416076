// Tables

table {
  width: 100%;
  table-layout: fixed;
}

th {
  vertical-align: middle;
  font-weight: bold;
}

td {
  vertical-align: middle;
}