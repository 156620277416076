.Header {
  color: #fff;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 300;
  background-color: $color1;
  transition: all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
  box-shadow: 0 2px 5px rgba(#000, .25);

  @media print {
    display: none;
  }

  @media (min-width: $mq-m) {
    box-shadow: none;
  }

  &::after {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    z-index: 100;
    visibility: hidden;
    opacity: 0;
    background-color: rgba(#000, 0.8);
    transition: all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
  }

  .has-nav & {
    transform: translateX(rem(230));

    &::after {
      opacity: 1;
      visibility: visible;
    }
  }

  &-lang {
    display: none;
    margin-left: rem(10);

    @media (min-width: $mq-m) {
      display: block;
    }

    select {
      width: rem(70);
    }

    .select2 {
      .select2-selection__rendered {
        height: rem(46);
        line-height: rem(46);
        color: #98daf3;
        font-size: rem(13);
        padding: 0 rem(10) 0 rem(20);
        transition: background .3s ease;

        .has-theme & {
          color: #FFF;
        }

        &::after {
          @extend %icon;
          @extend %icon-arr;
          color: #fff;
          font-size: rem(10);
          margin-left: rem(10);
          transform: rotate(90deg);
          transition: $transition-base;
        }
      }

      &.select2-container--open {
        .select2-selection__rendered {
          background-color: #18a6db;

          .has-theme & {
            background: rgba(0,0,0, 0.3);
          }

          &::after {
            transform: rotate(-90deg);
          }
        }
      }
    }
  }

  &-content {
    display: flex;
    padding: rem(10) rem(20) rem(10) rem(15);
    height: $header-height;
    justify-content: space-between;
    align-items: center;
    transition: $transition-base;

    @media (min-width: $mq-m) {
      height: $header-height-m;
      padding: 0 rem(40);

      .is-min & {
        height: $nav-height;
        padding-right: 0;
      }
    }

    .is-lang & {
      padding-right: rem(10);

      @media (min-width: $mq-m) {
        padding-right: rem(20);
      }
    }
  }

  &-col {
    flex: 1;
    display: flex;
    align-items: center;

    &:last-child {
      justify-content: flex-end;
    }

    &--lang {
      .Header-lang {
        display: block;
      }
    }
  }

  &-toggle {
    $btn-size: 20px;
    position: relative;
    border: 0;
    width: rem(30);
    height: rem(30);
    background-color: transparent;

    @media (min-width: $mq-m) {
      display: none;
    }

    span {
      left: 5px;
      top: 50%;
      margin-top: -2px;
      width: $btn-size;
      height: 4px;
      position: absolute;
      display: inline-block;
      background-color: #fff;
      border-radius: 3px;

      &::before, &::after {
        content: "";
        z-index: 3;
        left: 0;
        position: absolute;
        display: block;
        overflow: hidden;
        padding: 4px 0 0 $btn-size;
        background-color: #fff;
        border-radius: 3px;
      }

      &::before {
        top: 8px;
      }

      &::after {
        top: -8px;
      }
    }
  }

  &-search {
    height: rem(30);
    transition: $transition-base;

    @media (min-width: $mq-m) {
      position: relative;
      height: rem(40);

      .is-min & {
        height: $nav-height;
      }
    }

    &-field {
      height: rem(40);
      border-radius: rem(20);
      border: 0;
      position: absolute;
      right: rem(10);
      left: rem(10);
      top: rem(10);
      display: block;
      width: calc(100vw - #{rem(20)});
      font-size: rem(13);
      color: #d9d9d9;
      padding: rem(10) rem(120) rem(10) rem(20);
      opacity: 0;
      visibility: hidden;
      transition: $transition-base;

      .is-min & {
        @media (min-width: $mq-m) {
          height: $nav-height;
          width: rem(360);
          border-radius: 0;
        }
      }

      @include placeholder {
        color: #d9d9d9;
      }

      .has-theme &:focus + button {
        color: #7f7f7e;
      }

      @media (min-width: $mq-m) {
        width: rem(264);
        color: #fff;
        opacity: 1;
        visibility: visible;
        position: static;
        background-color: #18a6db;

        .has-theme & {
          background: rgba(0,0,0,0.3);
        }

        @include placeholder {
          color: #fff;
        }

        &:focus {
          color: #7f7f7e;
          background-color: #fff;

          & + button {
            color: #18a6db;
          }

          & ~ .Header-search-qwant {
            visibility: visible;
            opacity: 1;
            transform: translateY(-50%);
            transition: all .3s .1s ease;
          }
        }
      }

      .is-open & {
        opacity: 1;
        visibility: visible;
      }
    }

    &-btn {
      height: rem(30);
      width: rem(30);
      border: 0;
      color: #18a6db;
      font-size: rem(20);
      padding: 0;
      position: relative;
      background-color: transparent;
      transition: $transition-base;

      .has-theme & {
        color: rgba(255,255,255,0.3);
      }
  
      [class*="icon"] {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }

      @media (min-width: $mq-m) {
        color: #fff;
        position: absolute;
        right: rem(12);
        top: 50%;
        transform: translateY(-50%);
      }
    }

    &-qwant {
      position: absolute;
      top: 50%;
      width: rem(50);
      height: auto;
      right: rem(60);
      transform: translateY(-50%) translateX(rem(20));
      transition: all .3s ease;
      opacity: 0;
      visibility: hidden;

      @media (min-width: $mq-m) {
        right: rem(45);
      }

      .is-open & {
        visibility: visible;
        opacity: 1;
        transform: translateY(-50%);
        transition: all .3s .1s ease;
      }
    }
  }

  &-logo {
    @media (min-width: $mq-m) {
      padding-left: rem(72);
      position: relative;
      transition: $transition-base;

      &::before {
        content: "";
        display: block;
        width: rem(70);
        height: rem(47);
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        background: url(../img/logo-medef.svg);
        transition: $transition-base;
        background-size: cover;

        .is-min & {
          opacity: 0;
          transform: translate(rem(-72), -50%);
        }

        .has-theme & {
          background-image: url(../img/logo-medef-light.svg);
        }
      }

      .is-min & {
        padding-left: 0;
      }
    }

    img {
      width: rem(67);
      height: auto;
    }
  }

  &-btn {
    color: #98daf3;
    font-size: rem(13);
    display: none;

    .has-theme & {
      color: #FFF;
    }

    @media (min-width: $mq-m) {
      display: inline-block;
    }
  }

  &-back {
    display: inline-block;
    color: #98daf3;
    font-size: rem(13);

    &:hover {
      color: #fff;
    }

    i {
      color: #fff;
      font-size: rem(16);
      margin-right: rem(15);
    }
  }

  &-department {
    display: inline-block;
    padding-left: 16px;
    font-size: 18px;
    top: 2px;
    left: 4px;
    position: relative;
    
    &:before {
      content: '';
      height: 15px;
      top: 0;
      bottom: 0;
      margin: auto;
      width: 2px;
      background: #FFF;
      position: absolute;
      left: 5px;
    }
  }

  &.is-min {
    & + .Nav {
      @media (min-width: $mq-m) {
        top: $nav-height;

        & + main > .Filters {
          top: $nav-height*2;
        }
      }
    }
  }
}
