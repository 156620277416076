// Layout

.u-cf {
  &:before,
  &:after {
    content: " ";
    display: table;
  }

  &:after {
    clear: both;
  }
}

.u-float {
  &Left  { float: left !important; }
  &Right { float: right !important; }
}

.u-align{
  &Top    { vertical-align: top !important; }
  &Bottom { vertical-align: bottom !important; }
  &Middle { vertical-align: middle !important; }
}

%wrapper {
  padding-left: rem(10);

  @media (min-width: $mq-m) {
    width: $wrapper-width-m;
    padding-left: 0;
    padding-right: 0;
    margin-left: auto;
    margin-right: auto;
  }

  @media (min-width: $mq-l) {
    width: $wrapper-width-l;
  }
}

.u-wrapper {
  @extend %wrapper;
}

.u-desktopOnly {
  display: none;

  @media (min-width: $mq-m) {
    display: block;
  }
}

.u-smallOnly {
  display: block;

  @media (min-width: $mq-m) {
    display: none;
  }
}

hr {
  background-color: $color1;
  height: 1px;
  border: 0;
  &.grey {
    background-color: #ebebeb;
  }
}

.whoRWeDowloads {
  margin-top: rem(20);
}

.Wysiwyg, .Layout {
  a[href] {
    text-decoration: underline;
    color: #0c84b1;

    &:hover, &:focus {
      text-decoration: none;
    }
  }
}