.Slider {
  margin-bottom: rem(40);
  background-color: #000;
  overflow: hidden;

  @media (min-width: $mq-m) {
    display: flex;
    margin: 0 auto rem(40);
    width: 100%;
    max-width: rem(1024);
  }

  @media (min-width: $mq-l) {
    margin-bottom: rem(80);
    max-width: $wrapper-width-l;
  }

  &-full {
    position: relative;
    height: rem(320);
    overflow: hidden;

    @media (min-width: $mq-m) {
      flex: 1;
      opacity: 0;
      height: rem(474);
      width: calc(100vh - #{rem(320)});
    }

    @media (min-width: $mq-l) {
      height: rem(544);
    }

    .Box-tag {
      @media (min-width: $mq-m) {
        left: rem(40);
      }
    }

    .Box {
      height: rem(320);
      position: relative;

      @media (min-width: $mq-m) {
        height: rem(474);
        background-position: center center;
      }

      @media (min-width: $mq-l) {
        height: rem(544);
      }

      &-title {
        padding-right: rem(20);
      }
    }
  }

  &-news {
    background-color: white;
    overflow: scroll;
    -webkit-overflow-scrolling: touch;
    height: rem(162);

    @media (min-width: $mq-m) {
      width: rem(360);
      height: rem(474);
    }

    @media (min-width: $mq-l) {
      height: rem(544);
    }

    &-wrap {
      display: table;

      @media (min-width: $mq-m) {
        display: block;
      }

      @media (max-width: $mq-m) {
        height: 100%;
      }
    }

    .Box {
      width: rem(246);
      min-width: rem(246);
      display: table-cell;
      height: 100%;
      vertical-align: middle;

      @media (min-width: $mq-m) {
        display: block;
        width: 100%;
        height: rem(200);
        overflow: hidden;
      }

      @media (min-width: $mq-l) {
        height: auto;

        img {
          transform: none;
        }
      }

      & + .Box {
        border-left: 1px solid #000;

        @media (min-width: $mq-m) {
          border-left: 0;
          border-top: 1px solid #000;
        }
      }

      &-title {
        padding-right: rem(20);
      }
    }
  }

  &-panel {
    color: #FFF;
    display: flex;
    align-items: center;
    position: relative;
    text-align: center;
    
    &:before {
      content: '';
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      position: absolute;
      background: $color1;

      @media (min-width: $mq-m) {
        opacity: 0.875;
      }
    }

    @media (min-width: $mq-m) {
      position: absolute;
      right: 0;
      width: rem(360);
      height: rem(474);
    }    

    @media (min-width: $mq-l) {
      height: rem(544);
    }

    &-wrap {
      position: relative;
      z-index: 1;
      padding: rem(20) rem(40);

      @media (min-width: $mq-m) {
        padding: rem(30) rem(50);
      }
    }

    &-title {
      
      @media (min-width: $mq-m) {
        margin-bottom: rem(40);
      }

      .Title {
        margin-bottom: rem(20);
      }

      .Title-txt, .Title-txt span {
        color: #FFF;
      }
    }

    &-txt {
      margin-bottom: rem(30);
      
      @media (min-width: $mq-m) {
        margin-bottom: rem(55);
      }
    }
  }

  &--territorial {
    position: relative;
    
    .Slider-full {
      width: 100%;
    }

    .slick-dots {
      @media (min-width: $mq-m) {
        right: rem(400);
      }
    }
  }
}

.slick-dots {
  @extend %list-reset;
  position: absolute;
  left: rem(16);
  bottom: rem(16);
  display: flex;

  @media (min-width: $mq-m) {
    left: auto;
    right: rem(26);
    bottom: rem(26);
  }

  li {
    width: rem(14);
    height: rem(14);

    &.slick-active button, button:hover {
      &::after {
        background-color: #fff;
      }
    }
  }

  button {
    border: 0;
    width: 0;
    height: 0;
    display: block;
    overflow: hidden;
    padding: rem(14) 0 0 rem(14);
    position: relative;
    background-color: transparent;

    &::after {
      content: "";
      display: block;
      width: rem(8);
      height: rem(8);
      border-radius: 50%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-color: #a8a9a1;
      transition: $transition-base;
    }
  }
}

.slick-arrow {
  display: block;
  width: 0;
  height: 0;
  overflow: hidden;
  padding: rem(42) 0 0 rem(42);
  background-color: #fff;
  border-radius: 50%;
  border: 0;
  position: absolute;
  top: - rem(70);
  right: rem(20);
  z-index: 80;
  color: $color1;
  transition: $transition-base;

  @media (min-width: $mq-m) {
    padding: rem(64) 0 0 rem(64);
    box-shadow: 0 0 1px rgba(#000, .25);
    top: 50%;
    right: auto;
    left: 50%;
    margin-left: rem(344);
    transform: translateY(-50%);
  }

  @media (min-width: $mq-l) {
    margin-left: rem(448);
  }

  .no-touchevents &:hover {
    color: #fff;
    background-color: #0c84b1;
  }

  &::after {
    @extend %icon;
    @extend %icon-arr;
    font-size: rem(10);
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  &.slick-prev {
    right: rem(66);

    @media (min-width: $mq-m) {
      right: auto;
      margin-left: - rem(406);
    }

    @media (min-width: $mq-l) {
      margin-left: - rem(512);
    }

    &::after {
      transform: translate(-50%, -50%) rotate(180deg);
    }
  }
}
