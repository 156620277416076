@font-face {
  font-family: "icons";
  src: url('../fonts/icons.eot');
  src: url('../fonts/icons.eot?#iefix') format('eot'),
       url('../fonts/icons.woff') format('woff'),
       url('../fonts/icons.ttf') format('truetype'),
       url('../fonts/icons.svg#icons') format('svg');
}

%icon {
  position: relative;
  display:inline-block;
  vertical-align: middle;
  font-family:"icons";
  font-style:normal;
  font-weight:normal;
  line-height:1;
  speak:none;
  text-decoration:inherit;
  text-transform:none;
}

@function icon-char($filename) {
  $char: "";

  @if $filename == agenda {
    $char: "\E001";
  }
  @if $filename == arr-prev {
    $char: "\E002";
  }
  @if $filename == arr-slide {
    $char: "\E003";
  }
  @if $filename == arr-top {
    $char: "\E004";
  }
  @if $filename == arr {
    $char: "\E005";
  }
  @if $filename == attachment {
    $char: "\E006";
  }
  @if $filename == check {
    $char: "\E007";
  }
  @if $filename == close {
    $char: "\E008";
  }
  @if $filename == dl {
    $char: "\E009";
  }
  @if $filename == dossier {
    $char: "\E00A";
  }
  @if $filename == email {
    $char: "\E00B";
  }
  @if $filename == fb-circle {
    $char: "\E00C";
  }
  @if $filename == fb {
    $char: "\E00D";
  }
  @if $filename == filter {
    $char: "\E00E";
  }
  @if $filename == fullscreen {
    $char: "\E00F";
  }
  @if $filename == grid-small {
    $char: "\E010";
  }
  @if $filename == grid {
    $char: "\E011";
  }
  @if $filename == lecture {
    $char: "\E012";
  }
  @if $filename == linkedin-circle {
    $char: "\E013";
  }
  @if $filename == linkedin {
    $char: "\E014";
  }
  @if $filename == list {
    $char: "\E015";
  }
  @if $filename == mail {
    $char: "\E016";
  }
  @if $filename == play {
    $char: "\E017";
  }
  @if $filename == plus {
    $char: "\E018";
  }
  @if $filename == post {
    $char: "\E019";
  }
  @if $filename == print {
    $char: "\E01A";
  }
  @if $filename == publication {
    $char: "\E01B";
  }
  @if $filename == search {
    $char: "\E01C";
  }
  @if $filename == see {
    $char: "\E01D";
  }
  @if $filename == service {
    $char: "\E01E";
  }
  @if $filename == share {
    $char: "\E01F";
  }
  @if $filename == tv {
    $char: "\E020";
  }
  @if $filename == twitter-circle {
    $char: "\E021";
  }
  @if $filename == twitter {
    $char: "\E022";
  }
  @if $filename == user {
    $char: "\E023";
  }
  @if $filename == youtube-circle {
    $char: "\E024";
  }

  @return $char;
}

@mixin icon($filename) {
  content: icon-char($filename);
}

[class*="icon-"] {
  @extend %icon;
}


.icon-agenda{
  &::before {
    @include icon(agenda);
  }
}
%icon-agenda {
  @include icon(agenda);
}

.icon-arr-prev{
  &::before {
    @include icon(arr-prev);
  }
}
%icon-arr-prev {
  @include icon(arr-prev);
}

.icon-arr-slide{
  &::before {
    @include icon(arr-slide);
  }
}
%icon-arr-slide {
  @include icon(arr-slide);
}

.icon-arr-top{
  &::before {
    @include icon(arr-top);
  }
}
%icon-arr-top {
  @include icon(arr-top);
}

.icon-arr{
  &::before {
    @include icon(arr);
  }
}
%icon-arr {
  @include icon(arr);
}

.icon-attachment{
  &::before {
    @include icon(attachment);
  }
}
%icon-attachment {
  @include icon(attachment);
}

.icon-check{
  &::before {
    @include icon(check);
  }
}
%icon-check {
  @include icon(check);
}

.icon-close{
  &::before {
    @include icon(close);
  }
}
%icon-close {
  @include icon(close);
}

.icon-dl{
  &::before {
    @include icon(dl);
  }
}
%icon-dl {
  @include icon(dl);
}

.icon-dossier{
  &::before {
    @include icon(dossier);
  }
}
%icon-dossier {
  @include icon(dossier);
}

.icon-email{
  &::before {
    @include icon(email);
  }
}
%icon-email {
  @include icon(email);
}

.icon-fb-circle{
  &::before {
    @include icon(fb-circle);
  }
}
%icon-fb-circle {
  @include icon(fb-circle);
}

.icon-fb{
  &::before {
    @include icon(fb);
  }
}
%icon-fb {
  @include icon(fb);
}

.icon-filter{
  &::before {
    @include icon(filter);
  }
}
%icon-filter {
  @include icon(filter);
}

.icon-fullscreen{
  &::before {
    @include icon(fullscreen);
  }
}
%icon-fullscreen {
  @include icon(fullscreen);
}

.icon-grid-small{
  &::before {
    @include icon(grid-small);
  }
}
%icon-grid-small {
  @include icon(grid-small);
}

.icon-grid{
  &::before {
    @include icon(grid);
  }
}
%icon-grid {
  @include icon(grid);
}

.icon-lecture{
  &::before {
    @include icon(lecture);
  }
}
%icon-lecture {
  @include icon(lecture);
}

.icon-linkedin-circle{
  &::before {
    @include icon(linkedin-circle);
  }
}
%icon-linkedin-circle {
  @include icon(linkedin-circle);
}

.icon-linkedin{
  &::before {
    @include icon(linkedin);
  }
}
%icon-linkedin {
  @include icon(linkedin);
}

.icon-list{
  &::before {
    @include icon(list);
  }
}
%icon-list {
  @include icon(list);
}

.icon-mail{
  &::before {
    @include icon(mail);
  }
}
%icon-mail {
  @include icon(mail);
}

.icon-play{
  &::before {
    @include icon(play);
  }
}
%icon-play {
  @include icon(play);
}

.icon-plus{
  &::before {
    @include icon(plus);
  }
}
%icon-plus {
  @include icon(plus);
}

.icon-post{
  &::before {
    @include icon(post);
  }
}
%icon-post {
  @include icon(post);
}

.icon-print{
  &::before {
    @include icon(print);
  }
}
%icon-print {
  @include icon(print);
}

.icon-publication{
  &::before {
    @include icon(publication);
  }
}
%icon-publication {
  @include icon(publication);
}

.icon-search{
  &::before {
    @include icon(search);
  }
}
%icon-search {
  @include icon(search);
}

.icon-see{
  &::before {
    @include icon(see);
  }
}
%icon-see {
  @include icon(see);
}

.icon-service{
  &::before {
    @include icon(service);
  }
}
%icon-service {
  @include icon(service);
}

.icon-share{
  &::before {
    @include icon(share);
  }
}
%icon-share {
  @include icon(share);
}

.icon-tv{
  &::before {
    @include icon(tv);
  }
}
%icon-tv {
  @include icon(tv);
}

.icon-twitter-circle{
  &::before {
    @include icon(twitter-circle);
  }
}
%icon-twitter-circle {
  @include icon(twitter-circle);
}

.icon-twitter{
  &::before {
    @include icon(twitter);
  }
}
%icon-twitter {
  @include icon(twitter);
}

.icon-user{
  &::before {
    @include icon(user);
  }
}
%icon-user {
  @include icon(user);
}

.icon-youtube-circle{
  &::before {
    @include icon(youtube-circle);
  }
}
%icon-youtube-circle {
  @include icon(youtube-circle);
}
