.Cases {
  margin: rem(40) 0 rem(80);
  position: relative;

  @media (min-width: $mq-m) {
    margin-top: rem(150);
  }

  &-slider {
    @media (min-width: $mq-m) {
      .js & {
        opacity: 0;
      }
    }
  }

  &-slides {
    min-height: rem(380);

    @media (min-width: $mq-m) {
      min-height: rem(472);
    }

    @media (min-width: $mq-l) {
      min-height: rem(638);
    }

    &.slick-initialized {
      .Box {
        display: block;
      }
    }
  }

  .Box {
    display: none;
    width: rem(240);
    border-left: 2px solid #fff;
    border-right: 2px solid #fff;

    @media (min-width: $mq-m) {
      width: rem(472);
      border: 0;
    }

    @media (min-width: $mq-l) {
      width: rem(638);
    }

    [class*="icon"] {
      z-index: 60;
    }

    &-thumb {
      &::after {
        background-color: rgba(#d0d0ce, 0.7);
      }
    }

    &-info {
      @media (min-width: $mq-m) {
        opacity: 0;
        transition: $transition-base;
      }
    }

    &.slick-center {
      .Box-thumb {
        &::after {
          opacity: 0;
          transition: all .3s .5s ease;
        }
      }

      .Box-info {
        opacity: 1;
        transition: all .3s .5s ease;
      }
    }

    &:not(.slick-center) {
      &:hover {
        .Box-thumb::after {
          opacity: 0.5;
        }
      }
    }
  }

  .slick-arrow {
    color: #fff;
    background-color: $color1;
    border: 0;

    .no-touchevents &:hover {
      color: #2f9bc4;
      background-color: #fff;
    }

    &.slick-prev {
      @media (min-width: $mq-m) {
        margin-left: - rem(266);
      }

      @media (min-width: $mq-l) {
        margin-left: - rem(348);
      }
    }

    &.slick-next {
      @media (min-width: $mq-m) {
        margin-left: rem(204);
      }

      @media (min-width: $mq-l) {
        margin-left: rem(290);
      }
    }
  }

  .BtnCircle {
    position: absolute;
    right: rem(20);
    bottom: - rem(21);

    @media (min-width: $mq-m) {
      right: auto;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}