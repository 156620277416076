.Error {
  &404 {
    padding: rem(20) rem(20) rem(40);
    text-align: center;
    max-width: rem(800);
    margin: 0 auto;

    @media (min-width: $mq-m) {
      padding: rem(20) 0 rem(40);
      display: flex;
      flex-direction: column;
      justify-content: center;
      min-height: calc(100vh - #{$header-height-m + $nav-height + rem(68)});
    }

    &-sub {
      color: #a8a9a1;
      text-transform: uppercase;
      margin-bottom: rem(10);

      @media (min-width: $mq-m) {
        margin-bottom: rem(25);
      }
    }

    &-title {
      color: $color1;
      line-height: 1;
      font-weight: normal;
      font-size: rem(45);
      font-family: $playfair;
      margin-bottom: rem(40);

      @media (min-width: $mq-m) {
        font-size: rem(70);
        margin-bottom: rem(80);
      }

      strong {
        display: block;
        color: #18a6db;
        font-size: rem(80);
        line-height: 0.6;

        @media (min-width: $mq-m) {
          font-size: rem(120);
          line-height: 0.4;
        }
      }
    }

    p {
      font-size: rem(18);
      color: $gray-dark;
      font-weight: bold;
      font-family: $playfair;
      line-height: 2;

      @media (min-width: $mq-m) {
        font-size: rem(24);
        line-height: 1.6;
      }
    }

    &-btn {
      margin-top: rem(20);

      @media (min-width: $mq-m) {
        margin-top: rem(40);
      }

      &::before {
        content: "";
        display: block;
        width: rem(80);
        height: rem(5);
        margin: 0 auto rem(30);
        background-color: #d0d0ce;

        @media (min-width: $mq-m) {
          margin-bottom: rem(60);
        }
      }
    }
  }
}