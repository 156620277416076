// Margins

.u-ma0 { margin:          0 !important; }
.u-mt0 { margin-top:      0 !important; }
.u-mr0 { margin-right:    0 !important; }
.u-mb0 { margin-bottom:   0 !important; }
.u-mx0 { margin-left:     0 !important; margin-right:  0 !important; }
.u-my0 { margin-top:      0 !important; margin-bottom: 0 !important; }

.u-ma { margin:          $spacer !important; }
.u-mt { margin-top:      $spacer !important; }
.u-mr { margin-right:    $spacer !important; }
.u-mb { margin-bottom:   $spacer !important; }
.u-mx { margin-left:     $spacer !important; margin-right:  $spacer !important; }
.u-my { margin-top:      $spacer !important; margin-bottom: $spacer !important; }
.u-mxAuto { margin-left: auto !important; margin-right: auto !important; }

.u-mas { margin:          $spacer/2 !important; }
.u-mts { margin-top:      $spacer/2 !important; }
.u-mrs { margin-right:    $spacer/2 !important; }
.u-mbs { margin-bottom:   $spacer/2 !important; }
.u-mxs { margin-left:     $spacer/2 !important; margin-right:  $spacer/2 !important; }
.u-mys { margin-top:      $spacer/2 !important; margin-bottom: $spacer/2 !important; }

.u-maxs { margin:          $spacer/4 !important; }
.u-mtxs { margin-top:      $spacer/4 !important; }
.u-mrxs { margin-right:    $spacer/4 !important; }
.u-mbxs { margin-bottom:   $spacer/4 !important; }
.u-mxxs { margin-left:     $spacer/4 !important; margin-right:  $spacer/4 !important; }
.u-myxs { margin-top:      $spacer/4 !important; margin-bottom: $spacer/4 !important; }

.u-mam { margin:          $spacer*1.5 !important; }
.u-mtm { margin-top:      $spacer*1.5 !important; }
.u-mrm { margin-right:    $spacer*1.5 !important; }
.u-mbm { margin-bottom:   $spacer*1.5 !important; }
.u-mxm { margin-left:     $spacer*1.5 !important; margin-right:  $spacer*1.5 !important; }
.u-mym { margin-top:      $spacer*1.5 !important; margin-bottom: $spacer*1.5 !important; }

.u-mal { margin:          $spacer*2 !important; }
.u-mtl { margin-top:      $spacer*2 !important; }
.u-mrl { margin-right:    $spacer*2 !important; }
.u-mbl { margin-bottom:   $spacer*2 !important; }
.u-mxl { margin-left:     $spacer*2 !important; margin-right:  $spacer*2 !important; }
.u-myl { margin-top:      $spacer*2 !important; margin-bottom: $spacer*2 !important; }

// Padding

.u-pa0 { padding:          0 !important; }
.u-pt0 { padding-top:      0 !important; }
.u-pr0 { padding-right:    0 !important; }
.u-pb0 { padding-bottom:   0 !important; }
.u-px0 { padding-left:     0 !important; padding-right:  0 !important; }
.u-py0 { padding-top:      0 !important; padding-bottom: 0 !important; }

.u-pa { padding:          $spacer !important; }
.u-pt { padding-top:      $spacer !important; }
.u-pr { padding-right:    $spacer !important; }
.u-pb { padding-bottom:   $spacer !important; }
.u-px { padding-left:     $spacer !important; padding-right:  $spacer !important; }
.u-py { padding-top:      $spacer !important; padding-bottom: $spacer !important; }

.u-pas { padding:          $spacer/2 !important; }
.u-pts { padding-top:      $spacer/2 !important; }
.u-prs { padding-right:    $spacer/2 !important; }
.u-pbs { padding-bottom:   $spacer/2 !important; }
.u-pxs { padding-left:     $spacer/2 !important; padding-right:  $spacer/2 !important; }
.u-pys { padding-top:      $spacer/2 !important; padding-bottom: $spacer/2 !important; }

.u-paxs { padding:          $spacer/4 !important; }
.u-ptxs { padding-top:      $spacer/4 !important; }
.u-prxs { padding-right:    $spacer/4 !important; }
.u-pbxs { padding-bottom:   $spacer/4 !important; }
.u-pxxs { padding-left:     $spacer/4 !important; padding-right:  $spacer/4 !important; }
.u-pyxs { padding-top:      $spacer/4 !important; padding-bottom: $spacer/4 !important; }

.u-pam { padding:          $spacer*1.5 !important; }
.u-ptm { padding-top:      $spacer*1.5 !important; }
.u-prm { padding-right:    $spacer*1.5 !important; }
.u-pbm { padding-bottom:   $spacer*1.5 !important; }
.u-pxm { padding-left:     $spacer*1.5 !important; padding-right:  $spacer*1.5 !important; }
.u-pym { padding-top:      $spacer*1.5 !important; padding-bottom: $spacer*1.5 !important; }

.u-pal { padding:          $spacer*2 !important; }
.u-ptl { padding-top:      $spacer*2 !important; }
.u-prl { padding-right:    $spacer*2 !important; }
.u-pbl { padding-bottom:   $spacer*2 !important; }
.u-pxl { padding-left:     $spacer*2 !important; padding-right:  $spacer*2 !important; }
.u-pyl { padding-top:      $spacer*2 !important; padding-bottom: $spacer*2 !important; }