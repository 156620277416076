.Form {
  width: rem(315);
  margin: 0 auto;

  &-inputs {
    @extend %list-reset;
    border: 1px solid #ebebeb;

    &-item {
      & + & {
        border-top: 1px solid #ebebeb;
      }
    }

    textarea,
    input[type="email"],
    input[type="number"],
    input[type="password"],
    input[type="search"],
    input[type="tel"],
    input[type="text"],
    input[type="url"],
    select {
      width: 100%;
      border: 0;
      display: block;
      font-size: rem(11);
      color: #7f7f7e;
      font-family: $open;
      font-weight: 600;
      text-transform: uppercase;
      padding: rem(20) rem(30);

      @include placeholder {
        color: #7f7f7e;
      }
    }

    textarea {
      height: rem(174);
      resize: none;
    }

    .select2 {
      .select2-selection__rendered {
        width: 100%;
        border: 0;
        display: block;
        font-size: rem(11);
        color: #7f7f7e;
        line-height: 1.4;
        font-family: $open;
        font-weight: 600;
        text-transform: uppercase;
        padding: rem(20) rem(50) rem(20) rem(30);
        background-color: #fff;

        &::after {
          @extend %icon;
          @extend %icon-arr-top;
          color: $color1;
          font-size: rem(10);
          transform: rotate(180deg);
          position: absolute;
          right: rem(30);
          top: 50%;
          margin-top: - rem(5);
        }
      }
    }
  }

  &-count {
    position: relative;

    &::after {
      @extend %icon;
      @extend %icon-mail;
      color: $color1;
      font-size: rem(15);
      position: absolute;
      left: rem(30);
      top: rem(21);
    }

    textarea {
      text-indent: rem(25);
      line-height: 1.5;
      padding-bottom: rem(40);
    }

    &-chars {
      color: #7f7f7e;
      font-family: $open;
      font-size: rem(11);
      font-weight: 600;
      position: absolute;
      right: rem(15);
      bottom: rem(15);
      padding: rem(5);
      background-color: #fff;
    }
  }

  &-captcha {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    padding: rem(17) rem(20) rem(17) rem(30);

    label {
      font-size: rem(11);
      color: #7f7f7e;
      line-height: 1.4;
      font-family: $open;
      font-weight: 600;
      text-transform: uppercase;
    }
  }

  &-submit {
    margin-top: rem(20);
  }

  &-btn {
    display: block;
    color: #fff;
    border: 0;
    font-weight: 600;
    font-family: $open;
    text-align: center;
    text-transform: uppercase;
    padding: rem(15);
    background-color: $color1;
    width: 100%;

    &:hover {
      background-color: #18a6db;
    }
  }

  .Title {
    margin-bottom: rem(15);
  }

  &Search {
    border: 1px solid $gray-lighter;
    border-radius: rem(40);
    position: relative;
    padding: 0 rem(40) 0 rem(20);
    background-color: #fff;

    &-input {
      border: 0;
      height: rem(38);
      color: #7f7f7f;
      width: 100%;
      font-size: rem(12);
      background-color: transparent;

      @include placeholder {
        color: #7f7f7f;
      }
    }

    &-btn {
      width: rem(40);
      height: rem(38);
      padding: 0;
      border: 0;
      position: absolute;
      right: 0;
      top: 0;
      color: $color1;
      background-color: transparent;

      &:hover {
        color: $color1-light;
      }
    }
  }
}