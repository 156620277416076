.Tweet {
  padding: rem(20) rem(25);
  background-color: #fff;
  width: rem(236);
  height: rem(236);
  position: relative;
  z-index: 1;
  transition: $transition-base;
  overflow: hidden;
  margin-bottom: 22px;

  &::after {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    z-index: -1;
    transition: opacity .6s ease;
    box-shadow: 0 46px 46px rgba(#000, 0.15);
  }

  .no-touchevents &:hover {
    z-index: 10;

    &::after {
      opacity: 1;
    }
  }

  @media (min-width: $mq-m) {
    width: rem(226);
    height: rem(226);
    padding: rem(20);
    margin-bottom: 0;
  }

  @media (min-width: $mq-l) {
    width: rem(310);
    height: rem(310);
    padding: rem(25) rem(30);
  }

  &-tag {
    font-size: rem(16);
    font-weight: bold;
    color: $color1;
    line-height: 1.2;
    font-family: $playfair;
  }

  &-time {
    display: block;
    color: #b8b9b3;
    font-size: rem(12);
    margin: rem(10) 0;

    @media (min-width: $mq-l) {
      margin-bottom: rem(20);
    }
  }

  &-txt {
    color: #303030;
    font-size: rem(13);
    max-height: rem(133);
    overflow: hidden;

    @media (min-width: $mq-l) {
      font-size: rem(16);
      line-height: 1.7;
      max-height: inherit;
    }

    a, span {
      color: $color1;
    }
  }

  &-icon {
    position: absolute;
    right: rem(20);
    top: rem(20);
    font-size: rem(16);
  }
}
