.Tag {
  color: #adabaa;
  display: inline-block;
  font-size: rem(12);
  font-weight: bold;
  font-family: $open;
  text-transform: uppercase;
  background-color: #f1f0ee;
  padding: rem(3) rem(25) rem(3) rem(10);
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  max-width: 100%;
  text-overflow: ellipsis;

  &-close {
    position: absolute;
    width: rem(25);
    height: 100%;
    right: 0;
    top: 0;
    color: #d0d0ce;

    &:hover {
      color: $color1;
    }

    [class*="icon"] {
      position: absolute;
      top: 50%;
      left: 50%;
      font-size: rem(8);
      transform: translate(-50%, -50%);
    }
  }
}

.Tags {
  margin-right: - rem(20);
  overflow: scroll;
  -webkit-overflow-scrolling: touch;

  @media (min-width: $mq-m) {
    margin: 0;
    overflow: visible;
    -webkit-overflow-scrolling: auto;
  }

  &-list {
    display: table;
    @extend %list-reset;

    @media (min-width: $mq-m) {
      display: flex;
      flex-wrap: wrap;
      margin-top: rem(-5);
    }

    li {
      display: table-cell;
      padding-right: rem(10);

      @media (min-width: $mq-m) {
        display: block;
        margin-top: rem(5);
      }
    }

    a {
      color: #adabaa;
      display: inline-block;
      font-size: rem(12);
      font-weight: bold;
      font-family: $open;
      white-space: nowrap;
      text-transform: uppercase;
      background-color: #f1f0ee;
      padding: rem(3) rem(10);
    }
  }
}
