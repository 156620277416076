.Notice {
  color: #fff;
  background-color: $color1;
  position: fixed;
  z-index: 400;
  left: 0;
  bottom: 0;
  width: 100%;
  font-size: rem(12);
  font-family: $open;

  &-content {
    padding: rem(15) rem(30);
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (min-width: $mq-m) {
      height: $header-height-m;
      flex-direction: row;
      justify-content: center;
    }
  }

  &-desc {
    @media (min-width: $mq-m) {
      max-width: rem(600);
      text-align: right;
      font-size: rem(11);
    }
  }

  &-btn {
    width: rem(40);
    height: rem(40);
    border: 0;
    background-color: #fff;
    border-radius: 50%;
    color: $color1;
    font-size: rem(13);
    font-weight: 600;
    margin: rem(15) 0 rem(10);
    border: 1px solid #fff;
    transition: $transition-base;

    @media (min-width: $mq-m) {
      margin: 0 rem(30);
    }

    &:hover {
      background-color: transparent;
      color: #fff !important;
    }
  }

  &-more {
    text-decoration: underline;

    @media (min-width: $mq-m) {
      font-size: rem(13);
    }

    &:hover {
      text-decoration: none;
    }
  }
}
