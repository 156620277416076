.Layout {
  margin: rem(20) auto rem(60);
  padding: 0 rem(20);

  @media (min-width: $mq-m) {
    margin: rem(50) auto rem(120);
    width: $wrapper-width-m;
    display: flex;
    padding: 0;
    align-items: flex-start;
  }

  @media (min-width: $mq-l) {
    width: $wrapper-width-l;
  }

  &--m0 {
    margin-bottom: rem(30);
    
    @media (min-width: $mq-m) {
      margin-bottom: 0;
    }
  }

  &--full {
    @media (min-width: $mq-m) {
      display: block;
    }
  }

  &-intro {
    font-size: rem(18);
    font-family: $playfair;
    color: $gray-dark;
    line-height: 1.7;
    font-weight: bold;
    margin-bottom: rem(40);

    @media (min-width: $mq-m) {
      font-size: rem(22);
      margin-bottom: rem(80);
    }

    @media (min-width: $mq-l) {
      font-size: rem(24);
    }
  }

  .Title {
    max-width: none;
  }

  &-aside {
    display: none;

    &-title {
      color: #fff;
      padding: rem(20);
      text-align: center;
      line-height: 1;
      font-family: $open;
      font-weight: 600;
      font-size: rem(14);
      color: #fff;
      margin: 0;
      text-transform: uppercase;
      background-color: $color1;
    }

    @media (min-width: $mq-m) {
      display: block;
      padding-left: rem(20);
      width: rem(238);
    }

    @media (min-width: $mq-l) {
      width: rem(320);
      padding-left: rem(40);
    }

    a[href] {
      text-decoration: none !important;
    }
  }

  &-menu {
    @extend %list-reset;
    border: 1px solid #ebebeb;
    width: rem(218);

    @media (min-width: $mq-l) {
      width: rem(320);
    }

    > li + li {
      border-top: 1px solid #ebebeb;
    }

    > li {
      overflow: hidden;

      &.is-active > a, > a:hover {
        background-color: #f8f8f8;
        color: #3b3a3a;
      }

      &.is-active > a::before {
        transform: none;
      }

      > a {
        display: flex;
        align-items: center;
        padding: 0 rem(20) 0 rem(30);
        color: #959594;
        font-family: $open;
        line-height: 1.2;
        font-weight: 600;
        height: rem(55);
        text-transform: uppercase;
        font-size: rem(11);
        position: relative;

        &::before {
          content: "";
          display: block;
          width: rem(5);
          bottom: -1px;
          position: absolute;
          left: 0;
          top: -1px;
          background-color: $color1;
          transition: $transition-base;
          transform: translateX(rem(-5));
        }
      }
    }

    ul {
      @extend %list-reset;

      li {
        border-top: 1px solid #ebebeb;

        &:nth-child(odd) {
          background-color: #f8f8f8;
        }
      }

      a {
        display: block;
        color: #9d9d9d;
        font-size: rem(11);
        font-family: $open;
        position: relative;
        padding: rem(12) rem(20) rem(12) rem(40);

        &:hover {
          color: $color1;
        }

        &::before {
          content: "•";
          display: block;
          color: $color1;
          position: absolute;
          left: rem(30);
          top: rem(12);
        }
      }
    }
  }

  &-next {
    display: flex;
    justify-content: flex-end;
    margin-top: rem(40);

    @media (min-width: $mq-m) {
      margin-top: rem(100);
    }

    a {
      color: #a8a9a1;
      font-size: rem(13);
      display: flex;
      align-items: center;

      &:hover {
        span {
          color: #fff;
          background-color: $color1;
        }
      }

      span {
        margin-left: rem(30);
        background-color: #f1f0ee;
        font-size: rem(12);
        width: rem(42);
        height: rem(42);
        color: $color1;
        line-height: rem(40);
        display: block;
        text-align: center;
        border-radius: 50%;
        font-family: $open;
        font-weight: bold;
        border: 1px solid #f1f0ee;
        position: relative;
        transition: $transition-base;
      }
    }
  }

  &-school {
    @media (min-width: $mq-m) {
      display: flex;
      flex-wrap: wrap;
      margin: rem(-40) rem(-15) 0;
    }

    &-item {
      @media (min-width: $mq-m) {
        width: 50%;
        padding: 0 rem(15);
        margin-top: rem(40);
      }

      & + & {
        margin-top: rem(60);

        @media (min-width: $mq-m) {
          margin-top: rem(40);
        }
      }

      &--btn {
        text-align: center;

        @media (min-width: $mq-m) {
          padding-top: rem(80);
        }
      }
    }

    &-title {
      margin-bottom: rem(40);
      color: #212121;
      display: flex;
      font-size: rem(25);
      font-family: $playfair;
      font-style: italic;
      position: relative;
      padding-left: rem(30);
      align-items: flex-end;

      @media (min-width: $mq-m) {
        margin-bottom: rem(60);
        padding-left: rem(50);
      }

      &::before {
        content: "";
        display: block;
        width: rem(5);
        height: rem(80);
        position: absolute;
        left: 0;
        top: rem(10);
        background-color: #d0d0ce;

        @media (min-width: $mq-m) {
          top: rem(45);
        }
      }

      br {
        @media (min-width: $mq-m) {
          display: none;
        }
      }

      span {
        color: $color1;
        font-size: rem(100);
        line-height: 0.8;
        margin-right: rem(30);
        position: relative;
        top: rem(6);

        @media (min-width: $mq-m) {
          font-size: rem(150);
          margin-right: rem(10);
        }

        @media (min-width: $mq-l) {
          margin-right: rem(30);
        }

        em {
          color: #18a6db;
        }
      }
    }

    p {
      line-height: 2;

      @media (min-width: $mq-m) {
        font-size: rem(18);
      }

      strong {
        display: block;
        color: $gray-dark;
        font-size: rem(16);
        margin-bottom: rem(20);
        font-family: $playfair;

        @media (min-width: $mq-m) {
          font-size: rem(18);
          margin: 0;
        }
      }
    }
  }

  .Wysiwyg {
    max-width: rem(880);

    .intro {
      @media (min-width: $mq-m) {
        margin-bottom: rem(40);
      }
    }

    .Title {
      margin-top: rem(40);

      &:first-child {
        margin-top: 0;
      }
    }
  }

  &-content {
    @media (min-width: $mq-m) {
      flex: 1;
    }

    .Title {
      margin-left: - rem(10);

      @media (min-width: $mq-m) {
        margin-left: 0;
      }
    }

    &--full {
      .Wysiwyg {
        max-width: rem(720);

        @media (min-width: $mq-l) {
          max-width: rem(960);
        }

        &--full {
          max-width: none;
          font-size: rem(14);
        }
      }
    }

    &--2cols {
      @media (min-width: $mq-m) {
        display: flex;
        justify-content: space-between;
      }

      .Title-txt {
        position: relative;
        z-index: 0;

        @media (min-width: $mq-m) {
          min-width: 270px;
        }
      }
    }
  }

  &-share {
    position: relative;
    z-index: 3;
    top: -20px;
  }  
}
