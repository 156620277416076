.Box {
  position: relative;
  display: block;
  overflow: hidden;
  outline: 0;

  .no-touchevents &:hover {
    .Box-img, .Box-cover {
      &::after {
        opacity: 0;
      }
    }
  }

  img {
    display: block;
    width: 100%;
    height: auto;
    object-fit:cover;
    /*position:ab"solute;
    top:0;
    max-width:none;
    height:100%;
    left:50%;
    transform:translateX(-50%);*/
  }

  &-dl {
    width: rem(42);
    height: rem(42);
    border: 1px solid $gray-lighter;
    border-radius: 50%;
    position: absolute;
    right: rem(20);
    top: 50%;
    font-size: rem(14);
    transform: translateY(-50%);
    transition: $transition-base;

    @media (min-width: $mq-m) {
      right: rem(40);
    }

    i[class*="icon"] {
      color: $color1-light !important;
      top: rem(14);
      left: rem(14);
      right: auto;
      transition: $transition-base;
    }
  }

  &-tag {
    display: inline-block;
    padding: rem(5) rem(10);
    color: #fff;
    position: absolute;
    left: 0;
    top: 0;
    line-height: 1.2;
    font-weight: bold;
    font-size: rem(12);
    font-family: $open;
    white-space: nowrap;
    text-transform: uppercase;

    @media (min-width: $mq-m) {
      top: rem(20);
      left: rem(30);
    }

    @media (min-width: $mq-l) {
      top: rem(38);
    }
  }

  &-title {
    color: #fff;
    font-weight: bold;
    font-size: rem(14);
    position: absolute;
    left: rem(20);
    bottom: rem(20);
    margin: 0;
    font-family: $playfair;

    @media (min-width: $mq-m) {
      font-size: rem(18);
      left: rem(30);
    }

    @media (min-width: $mq-l) {
      bottom: rem(30);
    }

    &--large {
      font-weight: normal;
      font-size: rem(24);
      line-height: 1.1;
      bottom: rem(60);

      &:first-letter {
        font-size: rem(50);
      }

      @media (min-width: $mq-m) {
        left: rem(40);
        bottom: rem(90);
        font-size: rem(40);
        padding-top: rem(20);

        &::after {
          content: "";
          display: block;
          width: rem(80);
          height: rem(5);
          position: absolute;
          left: 0;
          top: 0;
        }

        &:first-letter {
          font-size: rem(60);
        }
      }
    }
  }

  &-info {
    padding: rem(15) rem(20);
    position: relative;
    background-color: #fff;

    @media (min-width: $mq-m) {
      padding: rem(15) rem(20) rem(10);
      position: absolute;
      right: 0;
      bottom: 0;
      background-color: #fff;
      width: rem(144);
      min-height: rem(144);
    }

    @media (min-width: $mq-l) {
      width: rem(160);
      padding-top: rem(20);
      min-height: rem(160);
    }

    &::before {
      content: "";
      display: block;
      width: rem(80);
      height: rem(5);
      position: absolute;
      left: 0;
      top: - rem(5);
      background-color: $color1;
    }

    &-metas {
      display: flex;
      align-items: flex-start;
    }

    &-file {
      line-height: 1.2;
      color: #7f7f7f;
      font-size: rem(12);
      text-transform: uppercase;
      font-family: $open;
      margin-left: rem(20);

      i[class*="icon"] {
        position: static;
        color: #7f7f7f;
        margin-right: rem(5);
      }
    }

    &-cat {
      color: $color1;
      font-size: rem(12);
      font-weight: bold;
      font-family: $open;
      text-transform: uppercase;
      margin-bottom: rem(25);
      min-height: rem(40);
      line-height: 1.2;
      margin-bottom: rem(3);

      @media (min-width: $mq-m) {
        margin-bottom: rem(5);
        font-size: rem(10);
        min-height: rem(25);
      }

      @media (min-width: $mq-l) {
        font-size: rem(12);
      }
    }

    &-title {
      color: $gray-dark;
      font-size: rem(20);
      font-weight: bold;
      font-family: $playfair;
      line-height: 1.2;
      margin: 0;

      @media (min-width: $mq-m) {
        font-size: rem(12);
        min-height: rem(60);
      }

      @media (min-width: $mq-l) {
        font-size: rem(14);
        min-height: rem(70);
      }
    }

    &-time {
      font-size: rem(12);
      color: #a8a9a1;
      display: none;

      @media (min-width: $mq-m) {
        display: block;
        margin-top: rem(10);
      }
    }

    &-state {
      font-size: rem(12);
      color: #a8a9a1;
      margin-top: rem(10);
      color: $color1;
    }

    &-resume {
      color: #a8a9a1;
      margin: rem(15) 0 0;
      font-size: rem(12);
      display: none;

      @media (min-width: $mq-m) {
        display: block;
      }

      @media (min-width: $mq-l) {
        margin-top: rem(20);
        line-height: 2;
      }
    }

    &--center {
      text-align: center;
      padding: rem(15) rem(10);
      position: absolute;
      right: 0;
      left: 0;
      bottom: 0;
      background-color: #fff;
      width: 82%;
      margin: 0 auto;
      min-height: 0 !important;

      &:before {
        display: none;
      }

      @media (min-width: $mq-l) {
        padding-top: rem(20);
      }
    }
  }

  &-name {
    color: $gray-dark;
    font-size: em(20);
    font-family: $playfair, serif;
    line-height: 1.5;
    font-weight: bold;
    margin-bottom: 10px !important;
  }

  &-position {
    text-transform: uppercase;
    color: $color1;
    font-weight: 700;
    font-size: 12px;
    margin-bottom: 10px !important;
    font-family: $open;
  }

  &-thumb {
    position: relative;

    &::after {
      content: "";
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      background-color: #86c2d8;
      opacity: 0.7;
      transition: $transition-base;
    }

    .is-video & {
      &::before {
        @extend %icon;
        @extend %icon-play;
        color: #fff;
        font-size: rem(28);
        position: absolute;
        left: 50%;
        top: 50%;
        z-index: 10;
        transform: translate(-50%, -50%);
      }
    }
  }

  &-views {
    position: absolute;
    top: 15px;
    right: 15px;
    display: flex;
    align-items: center;
    color: white;

    [class*="icon"] {
      position: static !important;
      padding-left:  7px;
    }
  }

  &-img {
    position: relative;
    height:100%;
    display:flex;

    &::after {
      content: "";
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      background-color: rgba(#212121, 0.2);
      transition: all .5s ease-in-out;
    }

    .is-video:not(.gallery) & {
      &::before {
        @extend %icon;
        @extend %icon-play;
        color: #fff;
        font-size: rem(28);
        position: absolute;
        left: 50%;
        top: 50%;
        z-index: 10;
        transform: translate(-50%, -50%);
      }
    }
  }

  &-date {
    color: #fff;
    position: absolute;
    right: rem(20);
    top: rem(50);
    font-size: rem(12);
    text-transform: uppercase;
    line-height: 1;
    text-align: right;

    @media (min-width: $mq-m) {
      right: auto;
      left: rem(20);
      top: rem(20);
    }

    @media (min-width: $mq-l) {
      top: rem(50);
      left: auto;
      right: rem(20);
    }

    span {
      font-family: $playfair;
      font-size: rem(50);
      display: block;
      margin-top: - rem(10);
    }
  }

  &-cover {
    background-position: center center;
    background-size: cover;
    transition: all 1s linear;
    position: absolute;
    z-index: -1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;

    &::after {
      content: "";
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      background-color: rgba(#212121, 0.2);
      transition: all .5s ease-in-out;
    }
  }

  &-legend {
    position: absolute;
    left: 0;
    width: 100%;
    bottom: 0;
    color: #fff;
    font-size: rem(12);
    padding: rem(5) rem(10);
    background-color: rgba(#191919, 0.8);

    @media (min-width: $mq-m) {
      padding: rem(8) rem(20);
    }
  }

  [class*="icon"] {
    position: absolute;
    right: rem(20);
    top: rem(20);
    color: #fff;
    font-size: rem(16);
    transition: $transition-base;
  }

  &-see {
    display: block;
    width: rem(60);
    height: rem(60);
    border: 1px solid $color1;
    position: absolute;
    left: 50%;
    top: 50%;
    opacity: 0;
    border-radius: 50%;
    margin: rem(-30) 0 0 rem(-30);
    transition: $transition-base;

    [class*="icon"] {
      font-size: rem(28);
      top: 50%;
      right: auto;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  &.is-media {
    .no-touchevents &:hover {
      .Box-img {
        &::after {
          opacity: 1;
          background-color: rgba(#212121, 0.8);
        }
      }

      .Box-see {
        opacity: 1;
      }
    }
  }

  &.is-pub {
    .no-touchevents &:hover {
      .Box-info {
        &::before {
          transform: none;
        }
      }
    }

    .Box-thumb {
      img {
        transform: none;
        float: right;
      }
    }

    .Box-img {
      @media (min-width: $mq-m) {
        width: rem(125);
      }

      @media (min-width: $mq-l) {
        width: rem(168);
      }

      &::after {
        display: none;
      }

      img {
        transform: none !important;
        float: right;
      }
    }

    .Box-info {
      @media (min-width: $mq-m) {
        position: relative;
        right: auto;
        bottom: auto;
        width: auto;
        flex: 1;
        padding: 0 0 0 rem(30);
        background-color: transparent;

        &::before {
          top: - rem(25);
          left: - rem(160);
          transform: scaleX(0);
          transform-origin: 0 50%;
          transition: $transition-base;

          @media (min-width: $mq-l) {
            top: - rem(35);
            left: - rem(210);
          }
        }

        &-title {
          font-size: rem(15);
          min-height: 0;
        }

        &-resume {
          margin-top: 0.5rem;
        }
      }
    }

    @media (min-width: $mq-m) {
      display: flex;
      align-items: flex-start;
      padding: rem(25) rem(40) rem(25) rem(30);
      background-color: #f1f0ee;
      overflow: visible;

      &::before {
        content: "";
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        opacity: 0;
        z-index: -1;
        pointer-events: none;
        transition: opacity .6s ease;
        box-shadow: 0 46px 46px rgba(#000, 0.15);
      }

      [class*="icon"] {
        color: #d0d0ce;
      }

      .no-touchevents &:hover {
        background-color: #fff;
        z-index: 2;

        &::before {
          opacity: 1;
        }

        @each $num, $color in $catColors {
          &.Box--cat#{$num} {
            [class*="icon"] {
              color: #{$color};
            }
          }
        }
      }
    }

    @media (min-width: $mq-l) {
      padding: rem(35) rem(80) rem(35) rem(45);

      .Box-info {
        &-title {
          font-size: rem(20);
          margin-bottom: rem(30);
        }

        &-time {
          margin-bottom: 0;
        }
      }
    }
  }

  &--push {
    height: rem(320);
    background-position: center center;
    background-size: cover;

    @media (min-width: $mq-m) {
      height: rem(474);

      .js & {
        opacity: 0;
      }
    }

    @media (min-width: $mq-l) {
      height: rem(544);
    }

    .Box-tag {
      @media (min-width: $mq-m) {
        left: rem(40);
      }
    }

    .Box-title {
      bottom: rem(30);

      @media (min-width: $mq-m) {
        bottom: rem(80);
      }
    }
  }

  @each $num, $color in $catColors {
    &--cat#{$num} {
      .Box-tag, .Box-title--large::after, .Box-info::before {
        background-color: #{$color};
      }

      .Box-info-cat {
        color: #{$color};
      }
    }
  }

  &--noThumb {
    padding-top: rem(236);
    background-color: #f1f0ee;
    z-index: 1;
    overflow: visible;
    transition: background .3s ease;

    &::before {
      content: "";
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      opacity: 0;
      z-index: -1;
      pointer-events: none;
      transition: opacity .6s ease;
      box-shadow: 0 46px 46px rgba(#000, 0.15);
    }

    @media (min-width: $mq-m) {
      padding-top: 0;

      .no-touchevents &:hover {
        background-color: #fff;
        z-index: 2;

        &::before {
          opacity: 1;
        }

        .Box-info::before {
          transform: none;
        }

        @each $num, $color in $catColors {
          &.Box--cat#{$num} {
            [class*="icon"] {
              color: #{$color};
            }
          }
        }
      }

      .Box-info {
        position: static;
        width: auto;
        padding: rem(20) rem(40) rem(20) rem(20);
        background-color: transparent;

        &::before {
          top: 0;
          transform: scaleX(0);
          transform-origin: 0 50%;
          transition: $transition-base;
        }

        &-title {
          font-size: rem(16);
          line-height: 1.4;
          min-height: rem(70);

          @media (min-width: $mq-l) {
            font-size: rem(20);
            min-height: rem(90);
            margin-bottom: rem(20);
          }
        }
      }
    }

    [class*="icon"] {
      color: #d0d0ce;
    }
  }

  &--lite {
    @media (max-width: $mq-s-up) {
      width: auto !important;
      height: rem(140);
      display: flex;
      padding: rem(20);
      margin-top: -1px;
      border: 1px solid #f1f0ee;

      &.Box--noThumb {
        background-color: #fff;
      }

      .Box-img, .Box-thumb {
        order: 2;
        width: rem(98);
        height: rem(98);
        overflow: hidden;
      }

      .Box-thumb {
        &::after {
          display: none;
        }

        img {
          height: rem(98);
          width: auto;
          max-width: none;
          transform: translateX(-25%);
        }
      }

      .Box-date {
        right: rem(40);
        top: rem(40);
      }

      .Box-info {
        flex: 1;
        padding: 0 rem(20) 0 0;

        &::before {
          left: - rem(20);
          top: - rem(20);
        }

        &-cat {
          min-height: 0;
          margin-bottom: rem(10);
          padding-left: rem(20);
        }

        &-title {
          font-size: rem(14);
        }
      }

      [class*="icon"] {
        font-size: rem(11);
        top: rem(21);
        right: auto;
        left: rem(20);
        z-index: 20;
      }

      @each $num, $color in $catColors {
        &.Box--cat#{$num} {
          [class*="icon"] {
            color: #{$color};
          }
        }
      }

      .Grid-item--dual & {
        .Box-img {
          img {
            height: 100%;
            width: auto;
            transform: translateX(-25%);
          }
        }
      }

      &.is-video {
        &::after {
          font-size: rem(20);
          right: rem(60);
          left: auto;
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }
  }

  &--wide {
    @media (max-width: $mq-s-up) {
      width: auto !important;

      .Box-info {
        display: none;
      }
    }

    &.is-video {
      .Box-img::before {
        @media (min-width: $mq-m) {
          font-size: rem(68);
        }

        @media (min-width: $mq-l) {
          font-size: rem(90);
        }
      }
    }
  }

  &--list {
    width: auto !important;
    height: rem(140);
    display: flex;
    padding: rem(20) rem(80) rem(20) rem(20);
    margin-top: -1px;
    border: 1px solid #f1f0ee;

    @media (min-width: $mq-m) {
      height: rem(93) !important;
      background-color: #f1f0ee;
      border: 0;
      margin: 0;
      border: 0;
      z-index: 1;
      padding-right: rem(120);
      overflow: visible;

      &::before {
        content: "";
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        opacity: 0;
        z-index: -1;
        pointer-events: none;
        transition: opacity .6s ease;
        box-shadow: 0 46px 46px rgba(#000, 0.15);
      }

      .no-touchevents &:hover {
        background-color: #fff;
        z-index: 2;

        .Box-dl {
          background-color: $color1;
          border-color: $color1;

          i[class*="icon"] {
            color: #fff !important;
          }
        }

        &::before {
          opacity: 1;
        }

        .Box-info::before {
          transform: none;
        }

        [class*="icon"] {
          color: $color1 !important;
        }

        @each $num, $color in $catColors {
          &.Box--cat#{$num} {
            [class*="icon"] {
              color: #{$color} !important;
            }
          }
        }
      }
    }

    @media (min-width: $mq-l) {
      padding-right: rem(140);
      height: rem(105) !important;
    }

    .Box-date {
      right: rem(20);
      top: rem(40);
      color: #a8a9a1;
      left: auto;

      @media (min-width: $mq-m) {
        right: rem(50);
        top: rem(22);
      }

      @media (min-width: $mq-l) {
        right: rem(90);
      }
    }

    .Box-info {
      flex: 1;
      padding: 0;
      position: relative;
      top: auto;
      right: auto;
      min-height: 0;
      background-color: transparent;

      &::before {
        left: - rem(20);
        top: - rem(20);
        transform-origin: 0 50%;
        transition: $transition-base;

        @media (min-width: $mq-m) {
          transform: scaleX(0);
        }
      }

      &-cat {
        min-height: 0;
        margin-bottom: rem(10);
        padding-left: rem(20);

        @media (min-width: $mq-m) {
          padding-left: 0;
          margin-bottom: rem(15);
        }

        @media (min-width: $mq-l) {
          margin-bottom: rem(20);
        }

        &.Box-info-cat--noIcon {
          padding-left: 0;
        }
      }

      &-title {
        font-size: rem(14);
        min-height: 0;

        @media (min-width: $mq-m) {
          font-size: rem(16);
        }

        @media (min-width: $mq-l) {
          font-size: rem(20);
        }
      }

      &-resume {
        display: none;
      }

      &-time {
        position: absolute;
        top: 0;
        margin: 0;
        right: - rem(70);

        @media (min-width: $mq-l) {
          right: - rem(50);
        }
      }
    }

    [class*="icon"] {
      font-size: rem(11);
      top: rem(21);
      right: auto;
      left: rem(20);
      z-index: 20;

      @media (min-width: $mq-m) {
        right: rem(20);
        left: auto;
        color: #d0d0ce !important;
      }
    }

    @each $num, $color in $catColors {
      &.Box--cat#{$num} {
        [class*="icon"] {
          color: #{$color};
        }
      }
    }
  }

  &Contact {
    background-color: #f1f0ee;
    border: 1px solid #f1f0ee;
    transition: $transition-base;
    position: relative;

    .no-touchevents &:hover {
      background-color: #fff;
      z-index: 10;

      &::before {
        opacity: 1;
      }
    }

    &::before {
      content: "";
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      opacity: 0;
      z-index: -1;
      pointer-events: none;
      transition: opacity .6s ease;
      box-shadow: 0 46px 46px rgba(#000, 0.15);
    }

    &-head {
      padding: rem(20);
      background-color: #fff;
    }

    &-title {
      color: $color1;
      font-family: $playfair;
      font-size: rem(20);
      min-height: rem(72);
      margin-bottom: rem(10);
    }

    &-link {
      color: $gray-light;
      font-size: rem(12);

      &:hover {
        i {
          color: $color1;
        }
      }

      i {
        color: #000;
        font-size: rem(16);
        margin-right: rem(10);
        transition: $transition-base;
      }
    }

    &-info {
      @extend %list-reset;

      li {
        padding: rem(15) rem(20);
        font-size: rem(12);
        color: $gray-light;

        strong {
          display: block;
          font-size: rem(11);
          font-family: $open;
          font-weight: 600;
          color: #000;
          margin-bottom: rem(2);
          text-transform: uppercase;
        }

        & + li {
          border-top: 1px solid #fff;
        }
      }
    }
  }

  &Who {
    overflow: hidden;
    position: relative;
    display: block;
    background-color: #212121;

    .no-touchevents &:hover {
      .BoxWho-thumb {
        opacity: 0.6;
      }

      .BtnCircle {
        background-color: #2f9bc4;
        border-color: #2f9bc4;

        i {
          color: #fff;
          transform: translate(-50%, -50%) rotate(360deg);
        }
      }
    }

    &-thumb {
      height: rem(200);
      background-position: center center;
      background-size: cover;
      opacity: 0.25;
      transition: all .6s ease;

      @media (min-width: $mq-m) {
        height: rem(236);
      }

      @media (min-width: $mq-l) {
        height: rem(318);
      }
    }

    &-content {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      padding: rem(30) rem(20);
      text-align: center;
      color: #fff;

      @media (min-width: $mq-l) {
        padding-top: rem(60);
      }
    }

    &-title {
      color: $color1;
      font-size: rem(18);
      margin-bottom: rem(15);
      font-family: $playfair;

      @media (min-width: $mq-m) {
        font-size: rem(16);
      }

      @media (min-width: $mq-l) {
        font-size: rem(18);
        min-height: rem(60);
        margin-bottom: rem(10);
      }
    }

    &-desc {
      margin: 0 auto;
      color: #d3d4d0;
      font-size: rem(12);
      line-height: 2;
      max-width: rem(180);

      @media (min-width: $mq-m) {
        line-height: 1.2;
        font-size: rem(14);
      }
    }

    .BtnCircle {
      position: absolute;
      left: 50%;
      bottom: rem(20);
      transform: translateX(-50%);

      @media (max-width: $mq-s-up) {
        color: #fff;
        background-color: $color1;
        border-color: $color1;
      }

      @media (min-width: $mq-l) {
        bottom: rem(60);
      }
    }
  }

  &Member {
    display: block;
    background-color: #f1f0ee;
    position: relative;
    text-align: center;
    z-index: 1;
    transition: $transition-base;

    &::before {
      content: "";
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      opacity: 0;
      z-index: -1;
      pointer-events: none;
      transition: opacity .6s ease;
      box-shadow: 0 46px 46px rgba(#000, 0.15);
    }

    &::after {
      content: "";
      display: block;
      width: rem(80);
      height: rem(5);
      background-color: $color1;
      position: absolute;
      left: 0;
      top: 0;
      transform: scaleX(0);
      transform-origin: 0 50%;
      transition: $transition-base;
    }

    .no-touchevents &:hover, &.is-open {
      background-color: #fff;
      z-index: 2;

      &::before {
        opacity: 1;
      }

      &::after {
        transform: none;
      }

      [class*="icon"] {
        color: $color1;
      }

      .BoxMember-thumb {
        opacity: 0;
        transform: translateY(rem(-20));
      }

      .BoxMember-content {
        padding-top: rem(30);

        @media (min-width: $mq-l) {
          padding-top: rem(60);
        }
      }

      .BoxMember-more {
        opacity: 1;
      }
    }

    &-content {
      height: rem(320);
      overflow: hidden;
      transition: $transition-base;
      padding: rem(180) rem(30) rem(20);

      @media (min-width: $mq-m) {
        height: rem(236);
        padding: rem(140) rem(20) rem(20);
      }

      @media (min-width: $mq-l) {
        height: rem(318);
        padding: rem(180) rem(30) rem(20);
      }
    }

    [class*="icon"] {
      position: absolute;
      right: rem(20);
      top: rem(20);
      color: #d0d0ce;
      font-size: rem(16);
      transition: $transition-base;

      @media (min-width: $mq-m) {
        top: rem(10);
        right: rem(10);
      }

      @media (min-width: $mq-l) {
        top: rem(20);
        right: rem(20);
      }
    }

    &-thumb {
      position: absolute;
      top: rem(35);
      left: 0;
      width: 100%;
      transition: $transition-base;
      background: url(../img/bg-photo.png) center 62% no-repeat;

      @media (min-width: $mq-m) {
        top: rem(20);
      }

      @media (min-width: $mq-l) {
        top: rem(35);
      }

      img {
        display: block;
        margin: 0 auto;
        border-radius: 50%;
      }
    }

    &-job, &-link {
      color: $color1;
      font-size: rem(12);
      text-transform: uppercase;
      font-family: $open;
      margin-bottom: rem(3);
      font-weight: bold;
    }

    &-name {
      color: $gray-dark;
      font-size: rem(20);
      font-family: $playfair;
    }

    &-more {
      margin-top: rem(15);
      opacity: 0;
      transition: $transition-base;

      @media (min-width: $mq-l) {
        margin-top: rem(30);
      }
    }

    &-desc {
      color: #a8a9a1;
      font-size: rem(12);
      line-height: 2;
      margin-bottom: rem(15);

      @media (min-width: $mq-l) {
        margin-bottom: rem(30);
      }
    }

    &-link {
      text-decoration: underline;
    }
  }

  &Client {
    display: block;
    background-color: #f1f0ee;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
    height: rem(320);
    transition: $transition-base;

    @media (min-width: $mq-m) {
      height: rem(236);
    }

    @media (min-width: $mq-l) {
      height: rem(318);
    }

    &::before {
      content: "";
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      opacity: 0;
      z-index: -1;
      pointer-events: none;
      transition: opacity .6s ease;
      box-shadow: 0 46px 46px rgba(#000, 0.15);
    }

    .no-touchevents &:hover {
      background-color: #fff;
      z-index: 2;

      &::before {
        opacity: 1;
      }
    }

    &-name {
      color: #a8a9a1;
      font-size: rem(12);
      position: absolute;
      bottom: rem(35);
      left: 0;
      margin: 0;
      font-weight: normal;
      width: 100%;
      text-align: center;
    }
  }

  &-thumbnail {
    width: 65px;
    height: 65px;
    margin-right: 20px;

    img {
      width: 100%;
      height: 100% !important;
      object-fit: cover;
    }
  }

  &--article-gallery {
    max-width: 60%;
    margin: 0 auto;
  }
}
