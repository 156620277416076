// Forms

textarea,
input[type="email"],
input[type="number"],
input[type="password"],
input[type="search"],
input[type="tel"],
input[type="text"],
input[type="url"],
select {
  outline: 0;
}

textarea {
  height: auto;
}

button {
  outline: 0;
  cursor: pointer;
  transition: $transition-base;
}

// Select

select {
  opacity: 0;
}

.select2-dropdown {
  &.is-lang {
    background-color: #18a6db;
    font-family: $open;
    font-weight: 600;
    border-bottom: 1px solid #98daf3;

    .has-theme & {
      background: #FFF;
      box-shadow: 0px 0px 5px rgba(0,0,0,0.1);
      border-bottom: 1px solid $gray-lighter;
      border-top: 0;
    }

    .select2-results__option {
      padding: rem(15) rem(15) rem(15) rem(20);
      line-height: 1.2;
      color: #92d7f1;
      font-size: rem(11);
      text-transform: uppercase;
      border-top: 1px solid #98daf3;
      position: relative;
      overflow: hidden;

      .has-theme & {
        border-top-color: $gray-lighter;
        color: $body-color;
      }

      &.select2-results__option--highlighted, &[aria-selected=true] {
        color: #fff;
        background-color: #0c84b1;

        .has-theme & {
          color: #000;
          background: lighten($gray-lighter, 15%);

          &:before {
            background: $gray;
          }
        }

        &::before {
          transform: none;
        }
      }

      &::before {
        content: "";
        display: block;
        width: rem(5);
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        background-color: #98daf3;
        transition: $transition-base;
        transform: translateX(rem(-5));

        .has-theme & {
          background: $gray;
        }
      }
    }
  }
}

input[type="checkbox"] {
  position: absolute;
  left: -9999em;
}

.new-checkbox {
  width: rem(20);
  height: rem(20);
  display: inline-block;
  border: 1px solid #e2e2e2;
  background-color: #fff;
  position: relative;

  &::after {
    @extend %icon;
    @extend %icon-check;
    color: $color1;
    font-size: rem(10);
    position: absolute;
    left: 50%;
    top: 50%;
    opacity: 0;
    transition: $transition-base;
    transform: translate(-50%, -50%) scale(0.9);
  }

  &.check {
    &::after {
      opacity: 1;
      transform: translate(-50%, -50%) scale(1);
    }
  }
}

#captcha-error {
  display: none;
  color: #f12121;
  padding: 10px;
}

body input#username {
  text-transform: none;
}

body input#firstname {
  text-transform: none;
}
