.Grid {
  margin: rem(40) 0;
  position: relative;

  @media (min-width: $mq-m) {
    margin-bottom: rem(80);
  }

  .Box {
    width: rem(236);
    min-width: rem(236);

    &--election {
      margin: 0 auto 5px;
      width: 100%;
      min-width: 0;
      max-width: 380px;

      @media (min-width: $mq-m) {
        margin: 0;
      }
    }

    @media (min-width: $mq-m) {
      width: 100%;
      height: rem(236);
    }

    @media (min-width: $mq-l) {
      height: rem(318);
    }
  }

  &-inner {
    overflow: scroll;
    -webkit-overflow-scrolling: touch;

    @media (min-width: $mq-m) {
      overflow: visible;
    }
  }

  &-wrap {

    @media (min-width: $mq-m) {
      display: flex;
      flex-wrap: wrap;
      margin: 0 -1px;
    }

    &--full {
      display: block;

      @media (max-width: $mq-s-up) {
        margin: 0 -1px;

        .Grid-sizer {
          width: 50%;
        }

        .Grid-item {
          width: 50%;
          padding: 1px;

          & + .Grid-item {
            border-left: 0;
          }

          &--dual {
            width: 100%;

            .Box {
              img {
                height: auto;
                width: 100%;
                transform: none;
              }
            }
          }
        }

        .Box {
          width: 100%;
          min-width: 0;
        }
      }
    }
  }

  &-sizer {
    @media (min-width: $mq-m) {
      width: 25%;

      &:not(.Grid-sizer--noPad) + .Grid-item {
        margin-left: 25%;

        .Grid--noTitle & {
          margin: 0;
        }
      }
    }
  }

  &-item {

    &:not(.Grid-item--fullWidth) {
      vertical-align: top;
    }

    &--fullWidth {
      margin: 0 auto;

      @media (max-width: $mq-m) {
        border-left: 0 !important;
      }

      img {
        @media (max-width: $mq-m) {
          height: 100% !important;
        }      
      }
    }

    @media (min-width: $mq-m) {
      display: block;
      padding: 1px;
      width: 25%;
      margin: 0;

      &:not(.Grid-item--noPad):first-child {
        margin-left: 25%;
      }

      .js & {
        opacity: 0;
      }
    }

    & + & {
      border-left: 4px solid transparent;

      @media (min-width: $mq-m) {
        border: 0;
      }
    }
    &--dual {
      @media (min-width: $mq-m) {
        width: 50%;
      }

      .Box {
        img {
          height: rem(236);
          width: auto;
          max-width: none;
          transform: translateX(-25%);

          @media (min-width: $mq-m) {
            transform: none;
            width: 100%;
            height: auto;
          }
        }
      }
    }

    &--triple {
      @media (min-width: $mq-m) {
        width: 75%;
      }

      .Box {
        @media (min-width: $mq-m) {
          height: rem(404);
        }

        @media (min-width: $mq-l) {
          height: rem(544);
        }
      }
    }

    &--full {
      @media (min-width: $mq-m) {
        width: 50%;
      }

      .Box {
        @media (min-width: $mq-m) {
          height: rem(474);
        }

        @media (min-width: $mq-l) {
          height: rem(638);
        }
      }
    }

    img {
      @media (max-width: $mq-m) {
        height: rem(236);
      }
    }
  }

  &-head {
    position: relative;
    z-index: 10;

    @media (min-width: $mq-m) {
      display: block;
      position: absolute;
      left: 0;
      top: rem(1);
    }

    .Btn {
      border-radius:0;
      text-transform: uppercase;
      margin: 25px 0 0 0;
      font-family: $open;
      font-size:rem(11);
      text-align: center;
      max-width:100%;

      @media (max-width: $mq-s) {
        width: 100%;
      }
    }

    .Title-txt {
      min-height:rem(80);
    }

    &-search {
      padding: 0 rem(20);
      margin: rem(40) 0;

      @media (min-width: $mq-m) {
        padding: 0;
        width: rem(215);
      }

      @media (min-width: $mq-l) {
        width: rem(240);
      }

      .SelectBtn {
        margin-bottom: rem(10);
      }
    }

    &-desc {
      padding: 0 rem(20);
      color: #a8a9a1;
      font-size: rem(12);
      line-height: 2;
      margin: rem(-20) 0 rem(30);

      @media (min-width: $mq-m) {
        padding: 0;
        margin: rem(-10) 0 0;
        max-width: rem(240);
      }

      @media (min-width: $mq-l) {
        max-width: rem(260);
        padding-left: rem(30);
      }
    }

    &--right {
      @media (min-width: $mq-m) {
        left: auto;
        right: 0;
      }

      + .Grid-inner .Grid-sizer:not(.Grid-sizer--noPad) + .Grid-item {
        @media (min-width: $mq-m) {
          margin-left: 0;
        }

        + .Grid-item:not(.Grid-item--dual) + .Grid-item {
          @media (min-width: $mq-m) {
            margin-right: 25%;
          }
        }

        + .Grid-item--dual {
          @media (min-width: $mq-m) {
            margin-right: 25%;
          }
        }
      }
    }
  }

  &-bg {
    padding: rem(40) 0 rem(60);
    background-color: #f1f0ee;

    @media (min-width: $mq-m) {
      padding: rem(80) 0;
    }

    .Grid {
      margin-top: 0;
    }

    .Title-txt {
      color: $color1;
    }
  }

  &--service {
    .Box-info-cat {
      margin-bottom: rem(20);
    }
  }

  &--tweets {
    margin: 0;

    .Grid-wrap {
      @media (min-width: $mq-m) {
        margin: 0 rem(-8);
      }
      
      @media (min-width: $mq-l) {
        margin: 0 rem(-5);
      }
    }

    .Grid-item {
      @media (min-width: $mq-m) {
        padding: rem(8);

        &:first-child {
          margin-left: 0;
        }

        &:nth-child(3) {
          margin-right: 25%;
        }
      }

      @media (min-width: $mq-l) {
        padding: rem(5);
      }
    }

    .Grid-head {
      @media (min-width: $mq-m) {
        left: auto;
        right: rem(8);
        top: rem(9);
      }

      @media (min-width: $mq-l) {
        right: rem(5);
      }
    }
  }

  &--radios {
    margin: 0;
    
    .Grid-wrap {
      @media (min-width: $mq-m) {
        margin: 0 rem(-8);
      }
      
      @media (min-width: $mq-l) {
        margin: 0 rem(-5);
      }
    }

    .Grid-item {
      @media (min-width: $mq-m) {
        padding: rem(8);

        &:first-child {
          margin-left: 0;
        }

        &:nth-child(3) {
          margin-right: 25%;
        }

        @media (min-width: $mq-l) {
          padding: rem(5);
        }
      }
    }
  }

  &--noScroll {
    margin-left: - rem(10);

    @media (min-width: $mq-m) {
      margin-left: 0;
    }

    .Title {
      margin-bottom: rem(40);
      margin-left: rem(10);
      margin-right: rem(10);

      @media (min-width: $mq-m) {
        margin-left: 0;
      }
    }

    .Grid-inner {
      overflow: visible;
      -webkit-overflow-scrolling: auto;
    }

    .Grid-wrap {
      display: block;

      @media (min-width: $mq-m) {
        display: flex;
      }
    }

    .Grid-item {
      display: block;

      & + .Grid-item {
        border-left: 0;
      }
    }
  }

  &--partners {
    .Grid-item + .Grid-item {
      margin-top: rem(2);

      @media (min-width: $mq-m) {
        margin-top: 0;
      }
    }
  }

  &--list {
    .Grid-item {
      @media (min-width: $mq-m) {
        width: 75%;
        margin-left: 25%;
      }
    }
  }

  &--wideBox {
    margin-bottom: 0;

    @media (min-width: $mq-m) {
      margin-bottom: rem(40);
    }

    & + .Grid {
      margin-top: 0;
    }
  }

  &--contact {
    .Grid-inner {
      padding: 0 rem(20);

      @media (min-width: $mq-m) {
        padding: 0;
      }
    }

    .Grid-item + .Grid-item {
      margin-top: rem(20);

      @media (min-width: $mq-m) {
        margin-top: 0;
      }
    }
  }

  &--who {
    .Grid-inner {
      padding: 0 rem(10);

      @media (min-width: $mq-m) {
        padding: 0;
      }
    }

    .Grid-item + .Grid-item {
      margin-top: rem(10);

      @media (min-width: $mq-m) {
        margin-top: 0;
      }
    }
  }

  &--space {
    margin-top: 0;

    @media (min-width: $mq-m) {
      margin-top: rem(40);
    }
  }
}
