#welcomeModal {
  &.modal {
    transition: opacity .5s linear;
    opacity: 0;
    position: fixed;
    z-index: 2147483647;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgb(0,0,0);
    background-color: rgba(0,0,0,0.6);
    .modal-content {
      background-color: #fefefe;
      margin: 0 auto;
      padding: 20px;
      border: 1px solid #888;
      width: 50%;
      min-width: 310px;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }

    .close {
      color: #aaa;
      float: right;
      font-size: 28px;
      font-weight: bold;
      margin-top: -20px;
      margin-bottom: -5px;
      height: 50px;
      width: 50px;
      text-align: right;
    }

    .close:hover,
    .close:focus {
      color: black;
      text-decoration: none;
      cursor: pointer;
    }
  }
}