.Post {
  position: relative;
  max-width: rem(839);
  margin: 0 auto;
  background-color: #fff;

  @media (min-width: $mq-m) {
    padding-left: rem(46);
    margin-top: $nav-height;;
    box-shadow: 0 46px 46px rgba(#000, 0.15);

    .Overlay & {
      margin-top: 0;
    }
  }

  @media (min-width: $mq-l) {
    max-width: rem(1084);
  }

  &-sticky-btn-event {
    position: fixed;
    z-index: 20;
    right: 25px;
    top: 240px;
    background: #327a8b;
    text-transform: uppercase;
    width: 225px;
    height: 80px;
    padding-left: 80px;
    line-height: 80px;
    font-size: 1.3em;
    border-radius: 6px;
    color: #fff;
    text-decoration: none;
    font-weight: 500;
    -webkit-box-shadow: 0 30px 40px rgba(0,0,0,.3);
    box-shadow: 0 30px 40px rgba(0,0,0,.3);
  }

  &-share {
    width: rem(42);
    height: rem(42);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: rem(20);
    color: $color1;
    border: 1px solid #d0d0ce;
    border-radius: 50%;
    position: absolute;
    top: rem(10);
    right: rem(20);
    cursor:pointer;
    z-index: 50;
    background: white;

    &--election {
      top: -10px;
      right: 0;
    }

    @media print {
      display: none;
    }

    @media (min-width: $mq-m) {
      display: none;
    }

    &.active {
      color:#fff;
      border-color:#fff;
      background: transparent;
    }
  }

  &-social {
    /*display: none;*/
    top:-70px;
    transition:all 0.3s ease;

    @media (min-width: $mq-m) {
      color: #fff;
      position: absolute;
      left: 0;
      top: 0;
      width: rem(46);
      height: 100%;
      display: block;
    }

    &-btns {
      @extend %list-reset;

      li + li {
        margin-top: rem(10);
      }

      a {
        display: block;
        width: rem(46);
        height: rem(46);
        font-size: rem(16);
        position: relative;

        i {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          color: #fff;
        }
      }
    }

     @media (max-width: $mq-m) {
      ul {
      }

      li {
        display:inline-block;
      }
      position:absolute;
      left:0;
      width:100%;

      &.active {
        top:0;
        z-index: 45;
      }
    }

    &--inline {
      background: $color1;
      left: -10px;
      top: -110px;
      width: 100vw;

      &.active {
        top: -20px;
      }

      @media (min-width: $mq-m) {
        position: static;
        width: auto;
        background: transparent;
        left: 0;
        width: 100%;

        .Post-social-btns {
          padding-left: 20px;

          a i {
            color: #d0d0ce;
          }   

          a {
            width: rem(32);
          }           
        }    
      }

      .Post-social-btns {
        a {
          text-decoration: none !important;
        }
        li {
          display: inline-block;
        }
      }
    }
  }

  &-content {
    overflow-x:hidden;
    padding: rem(40) rem(20) 0;

    @media (min-width: $mq-m) {
      padding: rem(40);
    }
  }

  &-inner {
    max-width: rem(880);
  }

  &-head {
    margin-bottom: rem(25);

    @media (min-width: $mq-m) {
      margin-bottom: rem(40);
    }

    &.has-date {
      @media (min-width: $mq-m) {
        .Post-title {
          padding-right: rem(240);
        }
      }
    }
  }

  &-date {
    display: flex;
    justify-content: center;
    margin: rem(40) 0;

    @media (min-width: $mq-m) {
      margin: 0;
      position: absolute;
      right: rem(36);
      top: rem(100);
    }

    &-item {
      width: rem(108);
      height: rem(100);
      margin: 0 rem(15);
      color: #fff;
      padding: rem(5);
      font-size: rem(10);
      font-family: $open;
      text-align: center;
      text-transform: uppercase;
      background: url(../img/bg-calendar.svg) center center no-repeat;
      background-size: cover;

      @media (min-width: $mq-m) {
        margin: 0 rem(2);
      }

      strong {
        display: block;
        font-size: rem(40);
        line-height: 1.4;

        small {
          font-size: rem(12);
          display: block;
          position: relative;
          font-weight: bold;
          top: - rem(10);
        }
      }

      span {
        font-weight: bold;
        color: #116f92;
        font-size: rem(12);
      }
    }
  }

  &-metas {
    color: #b8b9b3;
    font-size: rem(12);
    line-height: 1.4;
    margin-bottom: rem(5);
    padding-left: rem(25);

    @media (min-width: $mq-m) {
      position: absolute;
      right: rem(75);
      top: rem(40);
      margin: 0;
      padding: 0;
    }

    &--dark {
      color: $gray-dark;
    }
  }

  &-title {
    color: $gray-dark;
    font-size: rem(26);
    font-family: $playfair;
    margin-bottom: rem(15);

    @media (min-width: $mq-m) {
      font-size: rem(40);
      font-weight: normal;
      margin-bottom: rem(20);
    }
  }

  &-type {
    position: absolute;
    top: rem(40);
    left: rem(20);

    i {
      display: block;
    }

    @media (min-width: $mq-m) {
      display: block;
      top: rem(40);
      left: auto;
      right: rem(40);
    }
  }

  &-cat {
    line-height: 1;
    display: inline-block;
    white-space: nowrap;
    padding: rem(5) rem(10);
    color: #fff;
    font-size: rem(12);
    font-weight: bold;
    text-transform: uppercase;
    font-family: $open;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 40;

    @media (min-width: $mq-m) {
      position: static;
      margin-bottom: rem(30);

      .is-gp & {
        position: absolute;
        margin: 0;
        top: rem(40);
        left: rem(86);
      }
    }
  }

  &-sources {
    display: flex;
    @extend %list-reset;
    color: #a8a9a1;
    font-size: rem(9);
    flex-wrap: wrap;
    margin: rem(15) 0 rem(25);
    font-family: Arial, sans-serif;

    @media (min-width: $mq-m) {
      font-size: rem(10);
    }

    .Post-cover & {
      padding: 0 rem(20);

      @media (min-width: $mq-m) {
        padding: 0;
        margin: rem(10) 0 rem(-40);
      }
    }

    .Post-cover .Gallery-full & {
      margin: rem(10) 0 0;
    }

    li {
      white-space: nowrap;

      &:nth-child(2) {
        &::before {
          content: " - ";
          margin: 0 rem(1) 0 rem(4);

          @media (min-width: $mq-m) {
            margin: 0 rem(7) 0 rem(10);
          }
        }
      }

      & + li {
        &::before {
          @media (min-width: $mq-xs) {
            content: " - ";
            margin: 0 rem(1) 0 rem(4);
          }

          @media (min-width: $mq-m) {
            margin: 0 rem(7) 0 rem(10);
          }
        }
      }
    }
  }

  &-cover {
    margin: 0 rem(-20) rem(25);

    @media print {
      margin: 0 0 rem(60);
    }

    @media (min-width: $mq-m) {
      margin: 0 0 rem(60);
    }
  }

  &-more {
    text-align: center;
    margin-top: rem(60);

    @media (min-width: $mq-m) {
      text-align: right;
      margin: rem(80) 0 rem(120);
    }
  }

  &-gp {
    position: relative;
    margin: rem(-40) rem(-20) rem(60);

    @media (min-width: $mq-m) {
      margin: rem(-40) rem(-40) rem(40);
    }

    .Btn {
      position: absolute;
      top: 100%;
      left: 50%;
      z-index: 20;
      transform: translate(-50%, rem(-27));

      @media (min-width: $mq-m) {
        top: auto;
        left: auto;
        bottom: rem(40);
        right: rem(40);
        transform: none;
      }
    }

    .Box {
      height: rem(320);
      z-index: 1;

      @media (min-width: $mq-m) {
        height: rem(430);
      }

      @media (min-width: $mq-l) {
        height: rem(562);
      }

      &-title {
        bottom: rem(40);
        max-width: 70%;

        @media (min-width: $mq-l) {
          bottom: rem(160);
        }
      }
    }
  }

  @each $num, $color in $catColors {
    &.is-cat#{$num} {
      .Post {
        &-cat, &-social {
          background-color: #{$color};
        }

        &-type {
          color: #{$color};
        }

        &-social {
          &-btns {
            a:hover {
              color: darken($color, 20%);
            }
          }
        }

        &-pub-cat {
          color: #{$color};
        }
      }
      h2 {
        color: #{$color};
      }
    }
  }

  &-pub {
    margin: rem(40) 0;
    position: relative;
    padding-bottom: rem(60);

    @media (min-width: $mq-m) {
      margin: rem(60) 0;
      color: #fff;
      background-color: #000;
      padding: rem(40);
      display: flex;
    }

    @media (min-width: $mq-l) {
      padding: rem(70) rem(120);
    }

    > [class*="icon"] {
      display: none;

      @media (min-width: $mq-m) {
        display: block;
        position: absolute;
        top: rem(20);
        right: rem(20);
        color: #fff;
      }
    }

    &-thumb {
      @media (min-width: $mq-m) {
        width: rem(218);
      }

      @media (min-width: $mq-l) {
        width: rem(257);
      }

      img {
        display: block;
        margin: 0 auto;
        width: rem(168);

        @media (min-width: $mq-m) {
          width: auto;
        }
      }
    }

    &-info {
      display: none;

      @media (min-width: $mq-m) {
        display: block;
        flex: 1;
        padding-left: rem(40);
      }

      @media (min-width: $mq-l) {
        padding-left: rem(120);
      }
    }

    &-cat {
      font-size: rem(12);
      font-family: $open;
      font-weight: bold;
      text-transform: uppercase;
      margin-bottom: rem(30);
    }

    &-title {
      font-size: rem(30);
      font-family: $playfair;
      margin-bottom: rem(30);
    }

    &-time {
      display: block;
      margin-bottom: rem(30);
      font-size: rem(12);
    }

    &-resume {
      margin: 0;
      line-height: 2;
      font-size: rem(12);
    }

    .BtnCircle {
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      font-size: rem(14);

      @media (min-width: $mq-m) {
        display: block;
        bottom: - rem(21);
      }
    }
  }

  .Grid {
    margin: rem(100) rem(-20) 0 rem(-10);

    @media print {
      display: none;
    }

    @media (min-width: $mq-m) {
      margin: rem(120) 0 0;

      &-sizer {
        width: 33.3333%;

        & + .Grid-item {
          margin-left: 0;
        }
      }

      &-item {
        width: 33.3333%;

        &--dual, &--full {
          width: 66.6666%;
        }
      }
    }
  }

  .Embed {
    margin-left: rem(-20);
    margin-right: rem(-20);

    @media (min-width: $mq-m) {
      margin-left: 0;
      margin-right: 0;
    }

    @media (min-width: $mq-l) {
      width: rem(958);
    }
  }

  .Title {
    @media (min-width: $mq-m) {
      margin-bottom: rem(10);
    }
  }

  .Pagination {
    @media (min-width: $mq-m) {
      display: none;
    }
  }

  &-position {
    text-transform: uppercase;
    color: $color1;
    font-weight: 700;
    font-size: 12px;
    margin-bottom: 10px;
    font-family: $open;
  }  

  &-cols {

    .intro {
      padding-left: 0 !important;
    }
    
    .Post-intro {
      .intro {
        color: $gray-dark;
        font-size: rem(16);
        font-family: $playfair;
        line-height: 1.6;
        font-weight: bold;
        padding-left: rem(30);         
        
        @media (min-width: $mq-m) {
          font-size: rem(22);
          margin-bottom: rem(80);
        }           
      }        
    }

    @media (max-width: $mq-m) {

      .Post-cover {
        height: 0;
        overflow: hidden;
        position: relative;
        padding: 99% 0 0 0;

        img {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          object-fit: cover;
          height: 100%;
        }
      }
    }

    @media (min-width: $mq-m) {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-bottom: rem(30);
      
      .intro {
        margin-bottom: 0 !important;
      }

      .Post-cover {
        width: 30%;
        flex-basis: 30%;
        max-width: 30%;
        margin: 0;
      }

      .Post-intro {
        width: 66%;
        flex-basis: 66%;
        max-width: 66%; 
        margin: 0;
      }
    }
  }

  &-contacts {
    margin: 40px 0 0;

    @media (min-width: $mq-m) {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: rem(30);
      margin: 40px -9px 20px;
    }
  }

  &-contact {
    height: 122px;
    border: 2px solid #f1f0ee;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-family: $open;
    color: #140e0e;
    font-weight: bold;
    font-size: rem(12);
    margin-bottom: 20px;

    &-icon img {
      display: block;
      margin: 0 auto 10px;
    }

    &-wrap {
      padding: 0 10px;
    }
    
    @media (min-width: $mq-m) {
      width: 22%;
      flex-basis: 22%;
      max-width: 22%;       
      margin: 0 9px 0 9px;
      height: 203px;
    }

    @media (min-width: $mq-l) {
      width: 20%;
      flex-basis: 20%;
      max-width: 20%;       
    }
  }
}
