.List {
  &Push {
    @extend %list-reset;

    @media (min-width: $mq-m) {
      display: flex;
    }

    &-item {
      position: relative;
      background-size: cover;
      color: #fff;
      font-size: rem(12);
      line-height: 2;

      @media (min-width: $mq-m) {
        flex: 1;
      }

      @media (min-width: $mq-l) {
        font-size: rem(14);
      }

      & + & {
        margin-top: rem(2);

        @media (min-width: $mq-m) {
          margin-top: 0;
          margin-left: rem(2);
        }
      }
    }

    p {
      max-width: rem(200);
      margin: 0 auto;

      @media (min-width: $mq-l) {
        max-width: rem(220);
      }
    }

    .BtnCircle {
      position: absolute;
      bottom: rem(20);
      left: 50%;
      transform: translateX(-50%);
      color: #fff;
      background-color: $color1;
      border-color: $color1;

      @media (min-width: $mq-m) {
        bottom: rem(40);
      }

      @media (min-width: $mq-l) {
        bottom: rem(60);
      }

      &:hover {
        background-color: $color1;
        border-color: $color1;
      }
    }

    &-link {
      display: block;
      position: relative;
      min-height: rem(200);
      padding: rem(30) rem(20) rem(80);
      text-align: center;
      background-color: rgba(#000, 0.65);

      @media (min-width: $mq-m) {
        min-height: rem(336);
        padding: rem(50) rem(40) rem(100);
      }

      @media (min-width: $mq-l) {
        min-height: rem(520);
      }

      &:hover {
        .BtnCircle {
          background-color: #fff;
          border-color: #fff;

          i {
            color: $color1;
            transform: translate(-50%, -50%) rotate(360deg);
          }
        }
      }
    }

    &-title {
      font-size: rem(18);
      color: #18a6db;
      font-family: $playfair;

      @media (min-width: $mq-l) {
        font-size: rem(22);
      }

      br {
        display: none;

        @media (min-width: $mq-m) {
          display: block;
        }

        @media (min-width: $mq-l) {
          display: none;
        }
      }
    }
  }
}
